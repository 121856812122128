import { Customer, SltStatus } from './customer.model';
import { DQStatus } from './dataQuality.model';

export class Meter {
  id: number;
  typeId: number;
  customerId: number;
  status: MeterStatus;
  condition: MeterCondition;
  number: string;
  meterType: MeterType;
  communicationStatus?: CommunicationStatus;
  physicalMeterType?: string;
  avgCurrent: number;
  creditBalanceTime: Date | null;
  avgDailyAvailability: number;
  creditBalance: number;
  energy: number;
  lastVendPaymentKWH: number;
  lastVendPaymentNGN: number;
  lastVendPaymentTime: Date | null;
  power: number;
  voltage: number;
  lastUpdateTime?: string | null;
  creationDate?: string | null;
  lastUpdateUser?: string;
  firstCapturedUser?: string;
  assignedUser?: string;
  reassignedBy?: string;
  meterManufacturer?: string;
  typeOfMeter?: TypeOfMeter;
  readingOnMeter?: string;
  boxSeal?: string;
  ttbSeal?: string;
  ctSeal?: string;
  meterTypeDetails:string='';

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.typeId = data?.typeId ?? 0;
    this.customerId = data?.customerId ?? 0;
    this.status = data?.status ?? MeterStatus.NotSelected;
    this.condition = data?.condition ?? MeterCondition.NotSelected;
    this.number = data?.number ?? '';
    this.meterType = new MeterType(data?.meterType);
    this.communicationStatus =
      data?.communicationStatus ?? CommunicationStatus.NotSelected;
    this.physicalMeterType = data?.physicalMeterType ?? '';
    this.avgCurrent = data?.avgCurrent;
    this.creditBalanceTime = data?.creditBalanceTime;
    this.avgDailyAvailability = data?.avgDailyAvailability;
    this.creditBalance = data?.creditBalance;
    this.energy = data?.energy;
    this.lastVendPaymentKWH = data?.lastVendPaymentKWH;
    this.lastVendPaymentNGN = data?.lastVendPaymentNGN;
    this.lastVendPaymentTime = data?.lastVendPaymentTime;
    this.power = data?.power;
    this.voltage = data?.voltage;
    this.lastUpdateTime = data?.lastUpdateTime ?? '';
    this.creationDate = data?.creationDate ?? '';
    this.lastUpdateUser = data?.lastUpdateUser ?? '';
    this.firstCapturedUser = data?.firstCapturedUser ?? '';
    this.assignedUser = data?.assignedUser ?? '';
    this.reassignedBy = data?.reassignedBy ?? '';
    this.meterManufacturer = data?.meterManufacturer ?? '';
    this.typeOfMeter = data?.typeOfMeter;
    this.readingOnMeter = data?.readingOnMeter ?? '';
    this.boxSeal = data?.boxSeal ?? '';
    this.ttbSeal = data?.ttbSeal ?? '';
    this.ctSeal = data?.ctSeal ?? '';
    this.meterTypeDetails = data?.meterTypeDetails?.replace("_"," ") ?? '';
  }
}
export function getInitMeter(): Meter {
  return {
    id: 0,
    typeId: 0,
    customerId: 0,
    status: MeterStatus.NotSelected,
    condition: MeterCondition.NotSelected,
    number: '',
    meterType: {
      id: 0,
      name: '',
      utilityId: 0,
      meterSltStatuses: []
    },
    avgCurrent: 0,
    creditBalanceTime: null,
    avgDailyAvailability: 0,
    creditBalance: 0,
    energy: 0,
    lastVendPaymentKWH: 0,
    lastVendPaymentNGN: 0,
    lastVendPaymentTime: null,
    power: 0,
    voltage: 0,
    physicalMeterType: '',
    meterTypeDetails:'',

  };
}
export enum CommunicationStatus {
  CommunicatingWithAmiSystem = 1,
  NotCommunicatingWithAmiSystem = 2,
  NotSelected = 3,
}
export enum MeterCommunicationStatus {
  NotSelected = 0,
  CommunicatingWithAmiSystem = 1,
  NotCommunicatingWithAmiSystem = 2,
}
export class MeterType {
  id: number;
  name: string;
  utilityId: number;
  readingSteps?: MeterTypeReadingStep[];
  imageUrl?: string;
  imageFile?: File;
  meterSltStatuses: SltStatus[];
  currentSltStatuses?: string;
  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.name = data?.name ?? '';
    this.utilityId = data?.utilityId ?? 0;
    this.readingSteps = data?.readingSteps ?? undefined;
    this.imageUrl = data?.imageUrl ?? '';
    this.meterSltStatuses = data?.sltStatuses ?? undefined;
    this.currentSltStatuses = data?.currentSltStatuses ?? '';
  }
}

export enum MeterStatus {
  NotSelected = 0,
  Unmetered = 1,
  Metered = 2,
}

export enum MeterCondition {
  NotSelected = 0,
  Working = 1,
  NotWorking = 2,
  Burnt = 3,
}

export enum BillingType {
  NotSelected = 0,
  Prepaid = 1,
  Postpaid = 2,
}

export class MeterListElement {
  id: number = -1;
  customerId = 0;
  slrn: string = '';
  customerName: string = '';
  number: string = '';
  address: string = '';
  accountNumber: string = '';
  meterType: string = '';
  region: string = '';
  area: string = '';
  customerType: string = '';
  lastVendGhc: string = '';
  lastVendDateTime: Date = new Date();
  status: string = '';
  tagged: boolean = false;
  isChecked: boolean;
  dqStatus?: DQStatus;
}
export class MeterImage {
  url: string;
  imageType: any;

  constructor(data?: any) {
    this.url = data?.url ?? '';
    this.imageType = data?.imageType ?? '';
  }
}
export class InaccessibleMeter {
  number: string = '';
  meterImage: MeterImage;

  constructor(data?: any) {
    this.number = data?.number ?? '';
    this.meterImage = new MeterImage(data?.meterImage);
  }
}
export enum SortByOptions {
  NEWEST = 1,
  SLRN_ASC = 2,
  SLRN_DESC = 3,
  NUMBER_ASC = 4,
  NUMBER_DESC = 5,
}

export enum TypeOfMeter {
  Whole_Current = 1,
  LVCT_Operated = 2,
  HVCT_Operated = 3,
}

export class NewMeter {
  meterBrandId: number = 0;
  slrn: string = '';
  meterID: string = '';
  buildingSlrn: string = '';
  meterCondition: MeterCondition = MeterCondition.NotSelected;
  meterStatus: MeterStatus = MeterStatus.NotSelected;
  meterCommunicationStatus: MeterCommunicationStatus =
    MeterCommunicationStatus.NotSelected;
  assignedUserId: number = 0;
  number: string = '';
  customer: Customer = new Customer();
}

export class MeterTypeReadingStep {
  stepId?: number;
  stepOrder: number = 0;
  description: string = '';
}
