<div class="title">
  <p>{{ "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate }}</p>
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addNewMeterType"
    (click)="addNew()" *ngIf="
      permissionsService.isMeterTypeCreateEditAllowed &&
      utilityId != 0 &&
      bpsAdminLevel != 2
    ">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8 0C7.4477 0 7 0.44772 7 1V7H1C0.44772 7 0 7.4477 0 8C0 8.5523 0.44772 9 1 9H7V15C7 15.5523 7.4477 16 8 16C8.5523 16 9 15.5523 9 15V9H15C15.5523 9 16 8.5523 16 8C16 7.4477 15.5523 7 15 7H9V1C9 0.44772 8.5523 0 8 0Z"
        fill="white" />
    </svg>
    {{ "CRUD_SETTINGS.ADD_NEW" | translate }}
  </button>
</div>
<div class="container-fluid border-bottom">
  <table>
    <tr class="header-row">
      <th>{{ "COMMON.MBC_DETAILS.METER_TYPE.NAME" | translate }}</th>
      <th>{{ "COMMON.SLT_STATUS.SINGLE" | translate }}</th>
      <th></th>
    </tr>
    <tr class="content-wrapper" *ngFor="let meterType of meterTypes" data-bs-toggle="modal"
      data-bs-target="#addNewMeterType" (click)="
        permissionsService.isTsCreateEditAllowed
          ? selectMeterType(meterType)
          : null
      ">
      <td>{{ meterType.name | hasValue }}</td>
      <td>{{ meterType.currentSltStatuses | hasValue }}</td>
      <td class="td-svg">
        <svg data-bs-toggle="modal" data-bs-target="#deleteMeterType" (click)="selectMeterType(meterType)" width="22"
          height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" *ngIf="
            permissionsService.isMeterTypeDeleteAllowed && bpsAdminLevel != 2
          ">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
            fill="#DE350B" />
        </svg>
      </td>
    </tr>
  </table>
</div>
<div>
  <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
    (pageChange)="pageChange($event)"></app-paging>
</div>

<div class="modal fade" id="addNewMeterType" tabindex="-1" aria-hidden="true"
  *ngIf="permissionsService.isMeterTypeCreateEditAllowed" (hidden.bs.modal)="onModalFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ actionLabel$ | async }}
          {{ "COMMON.MBC_DETAILS.METER_TYPE.NAME" | translate }}
        </h5>
        <button type="button" #closeAddModal class="btn-cancel-modal" data-bs-dismiss="modal">
          {{ "COMMON.CANCEL" | translate }}
        </button>
      </div>
      <form id="addForm" class="form-wrapper" ngNativeValidate (submit)="save()">
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input id="meterTypeName" name="name" type="text" [(ngModel)]="selectedMeterType.name" class="form-control"
              required placeholder=" " />
            <label for="meterTypeName">{{
              "COMMON.MBC_DETAILS.METER_TYPE.NAME" | translate
              }}</label>
          </div>
          <div class="multi-select-group">
            <app-multi-select-dropdown
              [items]="sltOptions"
              [label]="'COMMON.SLT_STATUS.SINGLE' | translate"
              [showMore]="false"
              [requireApply]="false"
              [clicked]="clicked"
              (shareCheckedList)="selectSltStatus($event)"
            >
            </app-multi-select-dropdown>
          </div>
          <div class="instructions-button" *ngIf="
              selectedMeterType.readingSteps &&
              selectedMeterType.readingSteps.length > 0 &&
              !readingStepsVisible
            ">
            <button (click)="showReadingSteps($event)">
              {{
              "COMMON.MBC_DETAILS.METER_TYPE.SHOW_READING_STEPS" | translate
              }}
            </button>
          </div>
          <ng-container *ngIf="readingStepsVisible">
            <div *ngIf="!selectedMeterType.imageUrl || imageRemoved" class="img-preview">
              <input hidden type="file" id="choose-file" (change)="onFileSelected($event)" accept="image/*" />
              <label for="choose-file">
                <img src=" ../../../../assets/missing_image.png" alt="Missing image" />
              </label>
            </div>
            <div *ngIf="selectedMeterType.imageUrl && !imageRemoved" id="img-preview" class="img-preview">
              <img [src]="selectedMeterType.imageUrl" alt="Meter Type image" />
              <div class="img-operations">
                <span class="pointer-cursor" (click)="removeImage($event)">
                  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.94028 8.00012L13.1403 3.80679C13.2658 3.68125 13.3363 3.51099 13.3363 3.33346C13.3363 3.15592 13.2658 2.98566 13.1403 2.86012C13.0147 2.73459 12.8445 2.66406 12.667 2.66406C12.4894 2.66406 12.3192 2.73459 12.1936 2.86012L8.00028 7.06012L3.80695 2.86012C3.68142 2.73459 3.51115 2.66406 3.33362 2.66406C3.15608 2.66406 2.98582 2.73459 2.86028 2.86012C2.73475 2.98566 2.66422 3.15592 2.66422 3.33346C2.66422 3.51099 2.73475 3.68125 2.86028 3.80679L7.06028 8.00012L2.86028 12.1935C2.7978 12.2554 2.7482 12.3292 2.71436 12.4104C2.68051 12.4916 2.66309 12.5788 2.66309 12.6668C2.66309 12.7548 2.68051 12.8419 2.71436 12.9232C2.7482 13.0044 2.7978 13.0781 2.86028 13.1401C2.92226 13.2026 2.99599 13.2522 3.07723 13.2861C3.15847 13.3199 3.24561 13.3373 3.33362 13.3373C3.42163 13.3373 3.50876 13.3199 3.59 13.2861C3.67124 13.2522 3.74498 13.2026 3.80695 13.1401L8.00028 8.94012L12.1936 13.1401C12.2556 13.2026 12.3293 13.2522 12.4106 13.2861C12.4918 13.3199 12.5789 13.3373 12.667 13.3373C12.755 13.3373 12.8421 13.3199 12.9233 13.2861C13.0046 13.2522 13.0783 13.2026 13.1403 13.1401C13.2028 13.0781 13.2524 13.0044 13.2862 12.9232C13.3201 12.8419 13.3375 12.7548 13.3375 12.6668C13.3375 12.5788 13.3201 12.4916 13.2862 12.4104C13.2524 12.3292 13.2028 12.2554 13.1403 12.1935L8.94028 8.00012Z"
                      fill="#14213D" />
                  </svg>
                </span>
                <span>
                  <input hidden type="file" id="choose-file" (change)="onFileSelected($event)" accept="image/*" />
                  <label for="choose-file" class="pointer-cursor">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12 15.9861C11.4477 15.9861 11 15.5384 11 14.9861V7.82854L7.75748 11.0711L6.34326 9.65685L12.0001 4L17.657 9.65685L16.2428 11.0711L13 7.82831V14.9861C13 15.5384 12.5523 15.9861 12 15.9861ZM6 14H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V14H18V18H6V14Z"
                        fill="black" />
                    </svg>
                  </label>
                </span>
              </div>
            </div>
            <div class="reading-step" *ngFor="let step of selectedMeterType.readingSteps; let i = index">
              <div class="row">
                <div class="col-11 form-item form-floating mb-3">
                  <input [id]="'readingStep' + i" [name]="'readingStep' + i" type="text" [(ngModel)]="step.description"
                    class="form-control" required placeholder=" " />
                  <label [for]="'readingStep' + i">{{
                    "COMMON.MBC_DETAILS.METER_TYPE.READING_STEP" | translate
                    }}
                    {{ i + 1 }}</label>
                </div>
                <div class="col-1 remove-step">
                  <span (click)="removeStep($event, i + 1)" class="pointer-cursor">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 17" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.00782 1.32324C3.95773 1.32324 0.674484 4.60649 0.674484 8.65658C0.674484 12.7067 3.95773 15.9899 8.00782 15.9899C12.0579 15.9899 15.3412 12.7067 15.3412 8.65658C15.3412 4.60649 12.0579 1.32324 8.00782 1.32324ZM5.34115 7.98991C4.97296 7.98991 4.67448 8.28839 4.67448 8.65658C4.67448 9.02477 4.97296 9.32324 5.34115 9.32324H10.6745C11.0427 9.32324 11.3412 9.02477 11.3412 8.65658C11.3412 8.28839 11.0427 7.98991 10.6745 7.98991H5.34115Z"
                        fill="#FF0000" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="instructions-button" *ngIf="
              !selectedMeterType.readingSteps ||
              selectedMeterType.readingSteps.length == 0
            ">
            <button (click)="addReadingSteps($event, 1)">
              {{ "COMMON.MBC_DETAILS.METER_TYPE.READING_STEP" | translate }}
            </button>
          </div>
          <div class="instructions-button" *ngIf="
              selectedMeterType.readingSteps &&
              selectedMeterType.readingSteps.length > 0 &&
              readingStepsVisible
            ">
            <button (click)="
                addReadingSteps(
                  $event,
                  selectedMeterType.readingSteps.length + 1
                )
              ">
              {{ "COMMON.MBC_DETAILS.METER_TYPE.NEW_STEP" | translate }}
            </button>
          </div>
        </div>
        <div class="modal-footer" *ngIf="bpsAdminLevel != 2">
          <button class="btn btn-primary" form="addForm">
            {{ "COMMON.SAVE" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteMeterType" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "CRUD_SETTINGS.ARE_YOU_SURE_DELETE" | translate }}
          {{ "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate }}?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteMeterType()">
          {{ "COMMON.DELETE" | translate }}
        </button>
        <button #closeDeleteModal hidden="true" data-bs-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>