<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS.DETAILS.TITLE' | translate}}</div>
        <div class="slrn">{{ selectedSurvey.customer.accountNumber }}</div>
        <div [ngClass]="selectedSurvey.surveyInstallationStatus">
          {{ 'COMMON.ASSET_STATUS.' + selectedSurvey.surveyInstallationStatus | uppercase | translate }}
        </div>
      </div>
      <div class="user-address">
        <div>{{ selectedSurvey.customer.accountName }}</div>
        <div>{{ selectedSurvey.customer.physicalAddress }}</div>
      </div>
    </div>
    <div *ngIf="selectedSurvey.surveyInstallationStatus == 'Submitted'">
      <div class="row">
        <div class="col-6">
          <button
            *ngIf="permissionsService.isSurveyApproveRejectAllowed"
            class="btn btn-outline-danger"
            data-bs-toggle="modal"
            data-bs-target="#modalReject"
          >
            {{'SURVEYS.DETAILS.REJECT' | translate}}
          </button>
        </div>
        <div class="col-6">
          <button
            *ngIf="permissionsService.isSurveyApproveRejectAllowed"
            class="btn btn-outline-success"
            data-bs-toggle="modal"
            data-bs-target="#modalApprove"
          >
            {{'SURVEYS.DETAILS.APPROVE' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="selectedSurvey.surveyInstallationStatus == 'Unvalidated'">
      <div class="action-buttons">
        <button class="btn btn-header" *ngIf="!editEnabled && permissionsService.isSurveyCreateEditAllowed" (click)="edit()">{{'COMMON.EDIT' | translate}}</button>
        <button class="btn btn-outline-danger" *ngIf="editEnabled" (click)="edit()">{{'COMMON.CANCEL' | translate}}</button>
        <button class="btn btn-header" (click)="validate()" *ngIf="permissionsService.isSurveyCreateEditAllowed">{{'SURVEYS.DETAILS.VALIDATE' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{'COMMON.CUSTOMER.INFO' | translate}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</div>
        <div class="col-8">
          {{ selectedSurvey.customer.meterStatus | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate}}</div>
        <div class="col-8">
          {{ selectedSurvey.customer.customerType | hasValue }}
        </div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{'SURVEYS_INSTALLATIONS.FEEDER_INFO' | translate}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NUMBER' | translate}}</div>
        <div class="col-8">
          {{ selectedSurvey.dt.feederNumber | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</div>
        <div class="col-8">
          {{ selectedSurvey.dt.feederName | hasValue }}
        </div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{'COMMON.BU.SINGLE' | translate}}/{{'COMMON.UT.SINGLE' | translate}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.BU.SINGLE' | translate}}</div>
        <div class="col-8">
          {{ selectedSurvey.customer.regionName | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.UT.SINGLE' | translate}}</div>
        <div class="col-8">
          {{ selectedSurvey.customer.areaName | hasValue }}
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'COMMON.CUSTOMER.DETAILS' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.CUSTOMER_NAME' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.accountName | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.accountName"/>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.PHYSICAL_ADDRESS' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.physicalAddress | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TARIFF.T_NAME' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.tariffName | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TARIFF.T_RATE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.tariffRate | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TABLE_HEADERS.BUILDING_OWNER' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.buildingOwner | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.buildingOwner"/>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.DT_NUMBER' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.dt.dtNumber | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.DT_NAME' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.dt.dtName | hasValue }}
          </div>
          <div *ngIf="editEnabled">
            <select class="form-control" [(ngModel)]="selectedSurvey.customer.dtId">
                <option hidden selected>{{'TRANSFORMERS.SELECT' | translate}}</option>
                <option *ngFor="let option of dtOptions" value="{{option.key}}">{{option.value}}</option>
            </select>
        </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.PHONE_NO' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.phoneNo | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.phoneNo" pattern="[0-9]+" />
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.USER.EMAIL' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.email | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.email" type="email"/>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.OLD_METER_NO' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.oldMeterNo | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.oldMeterNo" />
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.customerType | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS.DETAILS.METER_BRAND' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.meterBrand | hasValue }}
          </div>
          <div *ngIf="editEnabled">
            <select class="form-control" [(ngModel)]="selectedSurvey.customer.meterBrandId">
              <option *ngFor="let option of meterBrandOptions" value="{{option.key}}">{{option.value}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.ADDRESS' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.address | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.address"/>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.ITINERARY' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.itinerary | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.meterStatus | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.SS.SINGLE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.dt.injectionSubstation | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CUSTOMER.CUSTOMER_PHASE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.typeOfConnection | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS.DETAILS.METER_READING' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.readingOnMeter | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.readingOnMeter"/>
        </div>
      </div>  
      <div [ngClass]="{'invisible': !isBlockNumberFieldVisible}" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CUSTOMER.BLOCK_NUMBER' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.block | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.block"/>
        </div>
      </div>
      <div [ngClass]="{'invisible': !isSPNFieldVisible}" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CONTRACT_NUMBER' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.customer.spn | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div [ngClass]="{'invisible': !isCurrentPlotCodeFieldVisible}" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CUSTOMER.CURRENT_PLOT_CODE' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.currentPlotCode | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.currentPlotCode"/>
        </div>
      </div>
      <div [ngClass]="{'invisible': !isActivityCodeFieldVisible}" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CUSTOMER.ACTIVITY_CODE' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.activityCode | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.activityCode"/>
        </div>
      </div>
      
      <div [ngClass]="{'invisible': !isGeoCodeFieldVisible}" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.GEOCODE' | translate | uppercase}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.geocode | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="selectedSurvey.customer.geocode" pattern="[0-9]+" />
        </div>
      </div>   
      <div [ngClass]="{'invisible': !isGhanaPostFieldsVisible}" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.GHANA_POST_GPS' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="!editEnabled" class="green-text">
            {{ selectedSurvey.customer.ghanaPostCode | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-12" [(ngModel)]="selectedSurvey.customer.ghanaPostCode"/>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="edit-customers-header" style="margin-top: 1rem">
            <div class="user-header-info">
              <div class="user-info">
                <div class="name">{{'SURVEYS.DETAILS.CUSTOMER_LOAD_ESTIMATES' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row tb-border" style="margin-left: 0.5rem">
          <table class="table table-striped">
            <thead>
              <th>{{'COMMON.TYPE' | translate}}</th>
              <th>{{'COMMON.TABLE_HEADERS.RATING' | translate}}</th>
              <th>{{'COMMON.TABLE_HEADERS.QUANTITY' | translate}}</th>
              <th>{{'COMMON.WATTAGE' | translate}}</th>
            </thead>
            <tbody>
              <tr *ngFor="let load of selectedSurvey.customerLoadEstimates">
                <td>{{ load.equipmentType | hasValue }}</td>
                <td>{{ load.rating | number | hasValue }}</td>
                <td>{{ load.quantity | number | hasValue }}</td>
                <td>{{ load.totalWattage | number | hasValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.INSTALL_DETAILS' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.TYPE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.installationType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.METER_LOC' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.meterLocation | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.SERVICE_WIRES_NUM' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.numberOfServiceWires | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.SERVICE_WIRES_COND' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.serviceWiresCondition | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.OUTPUT_CABLE_DIST' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.outputCableDistance | hasValue }} m
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.INPUT_CABLE_DIST' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.inputCableDistance | hasValue }} m
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.EXIST_CABLE_SIZE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.existingCableSize | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.EXIST_METER_LOC' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.existingMeterInLocation | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS.DETAILS.SERVICE_WIRES_TRACE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.serviceWireTraceableToMeterPoint | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.METER_ENCLOSURE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.existingMeterEnclosure | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.RECOMMENDED_METER' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.recommendedMeterType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.RECOMMENDED_CABLE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.recommendedCableSize | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.GEOLOCATION' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.latitude }} ,
            {{ selectedSurvey.longitude }}
          </div>
          <div class="col-1" style="margin-left: -2rem" (click)="isMapVisible = !isMapVisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              data-bs-toggle="modal"
              data-bs-target="#showMap"
            >
              <path
                d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                fill="#026962"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS.DETAILS.ISO_POINT' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedSurvey.isolationPoint | hasValue }}
          </div>
        </div>
      </div>
      <div class="col-3"></div>
      <div class="col-3"></div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="edit-customers-header" style="margin-top: 5rem">
            <div class="user-header-info">
              <div class="user-info">
                <div class="name">{{'SURVEYS_INSTALLATIONS.MATERIAL_REQ' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row tb-border">
          <table class="table table-striped">
            <thead>
              <th>{{'SURVEYS_INSTALLATIONS.MATERIAL_CATEGORY' | translate}}</th>
              <th>{{'SURVEYS_INSTALLATIONS.INSTALL_MATERIAL' | translate}}</th>
              <th>{{'COMMON.TABLE_HEADERS.QUANTITY' | translate}}</th>
              <th>{{'COMMON.TABLE_HEADERS.UNIT' | translate}}</th>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let requirament of selectedSurvey.installationMaterialRequirements
                "
              >
                <td>{{ requirament.materialCategory | hasValue }}</td>
                <td>{{ requirament.installationMaterial | hasValue }}</td>
                <td>{{ requirament.quantity | number | hasValue }}</td>
                <td>{{ requirament.unitOfMeasurement | hasValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.LOC_IMAGES' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS.DETAILS.BUILDING_FRONT' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getFrontView()">
            <img
              alt="Item image"
              src="{{ getFrontView() }}"
              (click)="showImage(1)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFrontView()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'SURVEYS.DETAILS.CABLE_CONNECTING' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getServiceCable()">
            <img
              alt="Item image"
              src="{{ getServiceCable() }}"
              (click)="showImage(2)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getServiceCable()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.EXIST_METERING_POINT' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getExistingMeterPoint()">
            <img
              alt="Item image"
              src="{{ getExistingMeterPoint() }}"
              (click)="showImage(3)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getExistingMeterPoint()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.RECOMMENDED_METERING_POINT' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getRecommendedMeterPoint()">
            <img
              alt="Item image"
              src="{{ getRecommendedMeterPoint() }}"
              (click)="showImage(4)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getRecommendedMeterPoint()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'SURVEYS.DETAILS.RECEIPTS' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getReceipts()">
            <img
              alt="Item image"
              src="{{ getReceipts() }}"
              (click)="showImage(5)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getReceipts()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4 card container">
        <div class="row">
          <div class="col header-title-text">
            {{'COMMON.MBC_DETAILS.METER_NO' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getMeterNumber()">
            <img
              alt="Item image"
              src="{{ getMeterNumber() }}"
              (click)="showImage(8)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getMeterNumber()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'COMMON.MBC_DETAILS.REMARKS' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row" style="width: 98.5%;">
      <div class="card container col-12">
        {{ selectedSurvey.remarksAndRecommendations | hasValue }}
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.FIELD_SIGNATURE' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.SIGNATURE' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getFieldRepSignature()">
            <img
              alt="Item image"
              src="{{ getFieldRepSignature() }}"
              (click)="showImage(6)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFieldRepSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.CUSTOMER_SIGNATURE' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.SIGNATURE' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getCustomersSignature()">
            <img
              alt="Item image"
              src="{{ getCustomersSignature() }}"
              (click)="showImage(7)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getCustomersSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>
</div>

<div
  class="modal modalMap"
  id="showMap"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          #closeAddModal
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map
          [setMapVisibility]="isMapVisible"
          [longitude]="selectedSurvey.longitude"
          [latitude]="selectedSurvey.latitude"
        ></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()" (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage" [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">&lt;</button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">&gt;</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalApprove"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'SURVEYS.DETAILS.APPROVE' | translate}}</h5>
      </div>
      <div>
        <div>
          {{'SURVEYS.DETAILS.APPROVE_PROMPT' | translate}}
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-outline-danger" style="width: 100%;" data-bs-dismiss="modal">
              {{'COMMON.CANCEL' | translate}}
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-success" style="width: 100%;" (click)="approve()">
              {{'SURVEYS.DETAILS.APPROVE' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div
  class="modal fade"
  id="modalReject"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'SURVEYS.DETAILS.REJECT' | translate}}</h5>
      </div>
      <form id="rejectForm" ngNativeValidate (submit)="reject()">
        <div class="form-item form-floating mb-3">
          <select id="rejectOption" class="form-select" (change)="onOptionChange($event)">
            <option [value]="'capturedUser'"> {{ 'COMMON.REJECT_OPTIONS.CAPTURED_USER' | translate }} </option>
            <option [value]="'fieldAgent'"> {{ 'COMMON.REJECT_OPTIONS.FIELD_AGENT' | translate }} </option>
          </select>
        </div>
        <div *ngIf="rejectToOption === 'fieldAgent'" class="form-item form-floating mb-3">
          <div class="form-floating form-item">
            <input
              type="text"
              name="filterText"
              [(ngModel)]="filterText"
              class="form-control filter-field"
              [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }"
              id="floatingInput"
              (ngModelChange)="filterMobileUsers()"
              placeholder=" "
            />
            <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

            <div
              class="filtered-agent-list"
              *ngIf="filterText && filterText.length > 2 && isDropdownOpened"
              #dropdownContainer
            >
              <div
                click-stop-propagation
                class="agent-name"
                (click)="selectFieldAgent(u.id)"
                *ngFor="let u of mobileUsers"
              >
                {{ u.firstName }} {{ u.lastName }}
              </div>
            </div>
          </div>
        </div>
        <label class="mb-3">
          {{'SURVEYS.DETAILS.REJECT_PROMPT' | translate}}
        </label>
        <div class="mb-3">
          <textarea required rows="10" cols="50" name="note" [(ngModel)]="rejectionNote" class="form-control" ></textarea>
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" data-bs-dismiss="modal" style="width: 100%;" class="btn btn-outline-danger">{{'COMMON.CANCEL' | translate}}</button>
          </div>
          <div class="col-6">
            <button type="submit" class="btn btn-danger" style="width: 100%;">
              {{'SURVEYS.DETAILS.REJECT' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
