import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReadingStatus } from 'src/app/models/meterReading';
import { PermissionsEnum } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { MeterReadingService } from 'src/app/services/meter-readings.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reading-details',
  templateUrl: './reading-details.component.html',
  styleUrls: ['./reading-details.component.scss']
})
export class ReadingDetailsComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    public meterReadingService: MeterReadingService,
    private translationService: TranslationService,
    private renderer: Renderer2,
    private userService: UserService
  ) {}
  ReadingStatus = ReadingStatus;
  PermissionsEnum = PermissionsEnum;
  
  selectedImage: string = '';
  meterReading: any | undefined; 
  id: string = '';
  editEnabled: boolean = false;
  onlyView: boolean = false;
  searchFilterApplied: boolean = false;
  
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');

  placeholderImage: string = '../../../../assets/no-image.png';
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  imgElement: any;

  priorityStatus: any[];
  buildingInformation: any[];
  readingInformation: any[];
  accountInformation: any[];
  meterInformation: any[];
  inaccessible: any[];
  inoperableMeter: any[];

  ngOnInit(): void {
    const readingId = this.activatedRoute.snapshot.paramMap.get('id');
    this.id = readingId || '';
    this.getMeterReading(readingId);
  }

  private getMeterReading(Id: any) {
    this.meterReadingService.get(Id).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.meterReading = responseData.data;
          this.generateSections();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  rotateRight() {
    this.currentRotation += 90;
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  showImage(url: string) {
    this.selectedImage = url;
  }
  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }

    this.selectedImage = '';
  }

  generateSections(){
    this.buildingInformation = [
      { title: 'Building Owner Name', value: this.meterReading?.buildingOwnerName, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: this.buName, value: this.meterReading?.region, titleWidth: 'col-5', bodyWidth: 'col-7'},
      { title: this.utName, value: this.meterReading?.district, titleWidth: 'col-5', bodyWidth: 'col-7'},
      { title: 'Geocode', value: this.meterReading?.geocode, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Block', value: this.meterReading?.block, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Round', value: this.meterReading?.round, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Plot', value: this.meterReading?.plot, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Address', value: this.meterReading?.address, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Ghana Post Address', value: this.meterReading?.ghanaPostAddress, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Geo location', value: `${this.meterReading?.latitude ?? ""}, ${this.meterReading?.longitude ?? ""}`, titleWidth: 'col-5', bodyWidth: 'col-6', isGeoLocation: true},
    ];
    this.readingInformation = [
      { title: 'Read Validation', value: this.meterReading?.readValidation, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Period', value:  this.meterReading?.period,titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'LAR', value:  this.meterReading?.lar,titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'PAR', value:  this.meterReading?.par,titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'MF', value:  this.meterReading?.mf,titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Consumption KWh', value: this.meterReading?.consumptionKwh, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Max Demand', value: this.meterReading?.maxDemand, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'LAR Kvah', value: this.meterReading?.larKvah, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'PAR Kvah', value: this.meterReading?.parKvah, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Apparent Power', value: this.meterReading?.apparentPower, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Power Factor', value: this.meterReading?.powerFactor, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Bill Amount', value: this.meterReading?.billAmount, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Daily Average', value: this.meterReading?.dailyAverage, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Read Source', value: this.meterReading?.readSource, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Treat As Actual', value: this.meterReading?.treatAsActual, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Audit Status', value: this.meterReading?.auditStatus, titleWidth: 'col-5', bodyWidth: 'col-7' },
      { title: 'Message Description', value: this.meterReading?.messageDescription, titleWidth: 'col-5', bodyWidth: 'col-7' }
    ];
    this.accountInformation = [
      { title: 'Account Number', value: this.meterReading?.accountNumber, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'SPN', value: this.meterReading?.spn, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Customer ID', value: this.meterReading?.customerId, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Tariff Class', value: this.meterReading?.tariffClass, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'SLT Status', value: this.meterReading?.sltStatus, titleWidth: 'col-4', bodyWidth: 'col-8'},
      { title: 'Contract Status (Account status)', value: this.meterReading?.contractStatus, titleWidth: 'col-6', bodyWidth: 'col-6'},
    ];
    this.meterInformation = [
      { title: 'Meter Number', value:  this.meterReading?.meterNumber, titleWidth: 'col-4', bodyWidth: 'col-8' },
      { title: 'Meter Code', value:  this.meterReading?.meterCode, titleWidth: 'col-4', bodyWidth: 'col-8' },
      { title: 'Meter Digit', value:  this.meterReading?.meterDigit, titleWidth: 'col-4', bodyWidth: 'col-8' },
    ];
    this.inaccessible = [
      { title: 'Inaccessible issue', value:  this.meterReading?.inaccessibleIssue, titleWidth: 'col-4', bodyWidth: 'col-8' },
      { title: 'Remark & Comment', value:  this.meterReading?.remarkAndComment, titleWidth: 'col-4', bodyWidth: 'col-8' },
    ];
    this.inoperableMeter = [
      { title: 'Meter condition', value:  this.meterReading?.meterCondition, titleWidth: 'col-4', bodyWidth: 'col-8' },
      { title: 'Remark & Comment', value:  this.meterReading?.remarkAndComment, titleWidth: 'col-4', bodyWidth: 'col-8' },
    ];
    this.priorityStatus = [
      {title: 'Priority', value: this.meterReading?.priority, titleWidth: 'col-4', bodyWidth: 'col-8'},
    ]
  }
}
