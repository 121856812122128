<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'POLES.EDIT.LT_SLRN' | translate}}</div>
        <div class="tag-customer" *ngIf="selectedPole.poleType">{{ 'COMMON.POLE.TYPE.' + selectedPole.poleType | uppercase | translate }} {{'COMMON.TYPE' | translate | lowercase}}</div>
        <div class="row">
          <div class="slrn col-7">
            {{ selectedPole?.slrn ? selectedPole?.slrn : "No SLRN" }}
          </div>
          <div class="col-2 pole-status complete-pole" *ngIf="
              disableUpdateStatusButton() &&
              selectedPole.dataQualityStatus!.toString() == 'Approved'
            ">
            {{'COMMON.ASSET_STATUS.COMPLETE' | translate}}
          </div>
          <div class="col-2 pole-status rejected-pole" *ngIf="
              disableUpdateStatusButton() &&
              selectedPole.dataQualityStatus!.toString() == 'Rejected'
            ">
            {{'COMMON.ASSET_STATUS.REJECTED' | translate}}
          </div>
          <div class="col-2 pole-status" *ngIf="!disableUpdateStatusButton() && selectedPole.status"
              [ngClass]="{'pending-pole' : selectedPole.status == 'Pending',
                          'assigned-pole' : selectedPole.status == 'Assigned' || selectedPole.status == 'Retagged'}"> 
            {{ 'COMMON.ASSET_STATUS.' + selectedPole.status | uppercase | translate }} 
          </div>
        <div class="col-2 pole-status pending-dt"
          *ngIf="!disableUpdateStatusButton() && !selectedPole.status">--------</div>
        </div>
      </div>
      <div class="user-address">
        <div></div>
        <div>{{ selectedPole.address }}</div>
        <div>{{ selectedPole.postCode | hasValue }}</div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="btn btn-header" data-bs-toggle="modal"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Retagged);" data-bs-target="#assignToFieldAgent"
        *ngIf="permissionsService.isPoleRetagAllowed">
        {{'COMMON.RETAG' | translate}}
      </button>
      <div class="recall-div" *ngIf="
          permissionsService.isPoleRecallAssignedAllowed ||
          permissionsService.isPoleRecallValidatedAllowed
        ">
        <button class="btn btn-header recall-button" style="color: black" (click)="showRecallMenu = !showRecallMenu">
          <span class="recall-span">{{'COMMON.RECALL' | translate}}</span>
        </button>
        <div *ngIf="showRecallMenu" class="recall-options">
          <div class="recall-type" [ngClass]="[
              !recallAssignedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="recallAssignedAllowed ? recall() : null">
            <span>{{'COMMON.ASSIGNED_TASK' | translate}}</span>
          </div>
          <div class="recall-type" [ngClass]="[
              !recallValidatedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="recallValidatedAllowed ? recall() : null">
            <span>{{'COMMON.VALIDATED_TASK' | translate}}</span>
          </div>
        </div>
      </div>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#updateStatus"
        [disabled]="disableUpdateStatusButton()" *ngIf="permissionsService.isPoleApproveRejectAllowed">
        {{'COMMON.UPDATE_STATUS' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#auditReview"
        [disabled]="disableAuditReviewButton()" *ngIf="permissionsService.isPoleAuditReviewAllowed">
        {{'COMMON.FIELD_AUDIT' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#assignToFieldAgent"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Assigned);"  *ngIf="permissionsService.isPoleAssignAllowed">
        {{'COMMON.ASSIGN_AGENT' | translate}}
      </button>
      <button class="btn btn-header" *ngIf="!editEnabled && permissionsService.isPoleCreateEditAllowed"
        (click)="edit()">
        {{'COMMON.EDIT' | translate}}
      </button>
      <button class="btn btn-primary" *ngIf="editEnabled" (click)="validateInput($event)">
        {{'COMMON.SAVE' | translate}}
      </button>
      <button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
        hidden></button>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="main-col">
      <div class="card container">
        <div class="row">
          <div class="col-4 header-title">{{'POLES.EDIT.LT_INFO' | translate}}</div>
          <div class="col-8"><hr /></div>
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.LT_SLRN' | translate}}</div>
          <div class="col-8">{{ selectedPole?.slrn | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.BU.SINGLE' | translate}}</div>
          <div class="col-8">{{ selectedPole?.region | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.UT.SINGLE' | translate}}</div>
          <div class="col-8">{{ selectedPole?.district | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.DT_NAME' | translate}}</div>
          <div class="col-8">{{ selectedPole?.dtName | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.DT_NUMBER' | translate}}</div>
          <div class="col-8">{{ selectedPole?.dtNumber | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</div>
          <div class="col-8">{{ selectedPole.feederName | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NUMBER' | translate}}</div>
          <div class="col-8">{{ selectedPole.feederNumber | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.LT_NUMBER' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedPole.poleNumber | hasValue }}
          </div>
          <input
            *ngIf="editEnabled"
            class="col-8"
            [(ngModel)]="selectedPole.poleNumber"
          />
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.TABLE_HEADERS.UPRISER_NUM' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedPole.upriserNumber | number | hasValue }}
          </div>
          <input
            *ngIf="editEnabled"
            class="col-8"
            [(ngModel)]="selectedPole.upriserNumber"
          />
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.ADD.TYPE_OF_POLE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedPole.poleType | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-8">
            <select class="form-control" [(ngModel)]="selectedPole.poleType">
              <option *ngFor="let option of poleTypesOptions">
                {{ option.value | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.SHAPE' | translate}}</div>
          <div class="col-8">{{ selectedPole.poleShape | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.POLE.CONDITION.TITLE' | translate}}</div>
          <div class="col-8">{{ selectedPole.poleCondition | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.NOTE' | translate}}</div>
          <div class="col-8">{{ selectedPole.poleNote | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.STRUCTURE' | translate}}</div>
          <div class="col-8">{{ selectedPole.poleStructure | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.CROSS_ARM' | translate}}</div>
          <div class="col-8">{{ selectedPole.crossArm | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.CROSS_ARM_COND' | translate}}</div>
          <div class="col-8">{{ selectedPole.crossArmCondition | hasValue }}</div>
        </div>
  
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.STREET_LIGHT' | translate}}</div>
          <div class="col-8">{{ selectedPole.streetLight | hasValue }}</div>
        </div>
        <div class="row" *ngIf="selectedPole.streetLight === 'Yes'">
          <div class="col-4">{{'POLES.EDIT.STREET_LIGHT_SRC' | translate}}</div>
          <div class="col-8">{{ selectedPole.streetLightSource | hasValue }}</div>
        </div>
        <div class="row" *ngIf="selectedPole.streetLight === 'Yes'">
          <div class="col-4">{{'COMMON.WATTAGE' | translate}}</div>
          <div class="col-8">{{ selectedPole.wattage | hasValue }}</div>
        </div>
        <div class="row" *ngIf="selectedPole.streetLight === 'Yes'">
          <div class="col-4">{{'POLES.EDIT.STATE' | translate}}</div>
          <div class="col-8">{{ selectedPole.state | hasValue }}</div>
        </div>
        <div class="row" *ngIf="selectedPole.streetLight === 'Yes'">
          <div class="col-4">{{'POLES.EDIT.ISOLATION' | translate}}</div>
          <div class="col-8">{{ selectedPole.meansOfIsolation | hasValue }}</div>
        </div>
  
        <div class="row">
          <div class="col-4">{{'POLES.ADDRESS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedPole.address | hasValue }}
          </div>
          <input
            *ngIf="editEnabled"
            class="col-8"
            [(ngModel)]="selectedPole.address"
          />
        </div>
        <div class="row">
          <div class="col-4">{{'POLES.EDIT.LT_ID' | translate}}</div>
          <div class="col-8">{{ selectedPole.poleId | hasValue }}</div>
        </div>
  
        <ng-container *ngIf="isGhanaPostFieldsVisible">
          <div class="row">
            <div class="col-5">{{'COMMON.GHANA_POST_GPS' | translate}}</div>
            <div class="col-7">{{ selectedPole.postCode | hasValue }}</div>
          </div>
          <div class="row" *ngIf="selectedPole.postCodeException">
            <div class="col-5">{{'COMMON.GHANA_POST_GPS_EXC' | translate}}</div>
            <div class="col-7">
              {{ selectedPole.postCodeException | hasValue }}
            </div>
          </div>
        </ng-container>
  
        <div class="row">
          <div class="locatonLabel col-5">{{'COMMON.MBC_DETAILS.GEO_LOCATION' | translate}}</div>
          <div class="locationText col-6">
            {{ selectedPole.latitude }}, {{ selectedPole.longitude }}
          </div>
          <div class="location col-1" (click)="isMapVisible = !isMapVisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              data-bs-toggle="modal"
              data-bs-target="#showMap"
            >
              <path
                d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                fill="#026962"
              />
            </svg>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.USER.TYPE.FIRST_CAPTURED' | translate}}</div>
          <div class="col-8">{{ selectedPole.firstCapturedUser | hasValue }}</div>
        </div>
        <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
          <div class="col-4">{{'COMMON.VALIDATION_STATUS' | translate}}</div>
          <div class="col-8" *ngIf="disableUpdateStatusButton() && selectedPole.dataQualityStatus!.toString() =='Approved'"> {{'COMMON.ASSET_STATUS.COMPLETE' | translate}} </div>
          <div class="col-8" *ngIf="disableUpdateStatusButton() && selectedPole.dataQualityStatus!.toString() =='Rejected'"> {{'COMMON.ASSET_STATUS.REJECTED' | translate}} </div>
          <div class="col-8" *ngIf="!disableUpdateStatusButton() && selectedPole.status"> 
            {{ selectedPole.status }} 
          </div>
          <div class="col-8" *ngIf="!disableUpdateStatusButton() && !selectedPole.status">--------</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.LAST_UPDATED' | translate}}</div>
          <div class="col-8">{{ selectedPole.lastUpdateUser | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.CREATION_DATE' | translate}}</div>
          <div class="col-8">
            {{selectedPole.creationDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.UPDATED_DATE' | translate}}</div>
          <div class="col-8">
            {{selectedPole.lastUpdate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.USER.TYPE.REASSIGNED' | translate}}</div>
          <div class="col-8">{{ selectedPole.reassignedBy | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.ASSIGNED' | translate}}</div>
          <div class="col-8">{{ selectedPole.assignedUser | hasValue }}</div>
        </div>
        <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
          <div class="col-4">{{'COMMON.USER.TYPE.VALIDATED' | translate}}</div>
          <div class="col-8">{{ selectedPole.validatedBy | hasValue }}</div>
        </div>
        <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
          <div class="col-4">{{'COMMON.DATE_TYPE.VALIDATED' | translate}}</div>
          <div class="col-8">
            {{selectedPole.validationDate | date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
          </div>
        </div>
        <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
          <div class="col-4">{{'COMMON.MBC_DETAILS.AUDITED_STATUS' | translate}}</div>
          <div class="col-8">{{ selectedPole.auditedStatus | hasValue }}</div>
        </div>
        <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
          <div class="col-4">{{'COMMON.USER.TYPE.AUDITED' | translate}}</div>
          <div class="col-8">{{ selectedPole.auditedBy | hasValue }}</div>
        </div>
        <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
          <div class="col-4">{{'COMMON.MBC_DETAILS.AUDITED_DATE' | translate}}</div>
          <div class="col-8">
            {{selectedPole.auditedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.COMMENT' | translate}}</div>
          <div class="col-8">{{ selectedPole.comment | hasValue }}</div>
        </div>
      </div>
      <app-history-tracker [histories]="selectedPole.poleHistories"></app-history-tracker>

      <div *ngIf="isDataQuality && selectedPole.dataValidationReview != null && selectedPole.dataValidationReview.length > 0" class="history-card container">
        <h4 style="text-align: center">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h4>
        <div class="row" *ngFor="let dq of selectedPole.dataValidationReview">
          <div class="col-5">{{ dq.key }}</div>
          <div *ngIf="dq.key != 'Reason for rejection'" class="col-7">
            {{ dq.value| hasValue }}
          </div>
          <div *ngIf="dq.key == 'Reason for rejection'" class="col-7">
            <a data-bs-toggle="modal" data-bs-target="#rejectionReason">{{'COMMON.VIEW' | translate | lowercase}}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="card container main-col">
      <div class="row">
        <div class="col-5 header-title">
          {{'POLES.EDIT.CONNECTED_BUILDINGS' | translate}}
          <span class="countOfBuildings" *ngIf="countOfBuilding != 0">{{
            currentBuildingPage + 1
            }}</span>
          <span class="countOfBuildings" *ngIf="countOfBuilding == 0">{{
            currentBuildingPage
            }}</span>
        </div>

        <div class="col-5">
          <hr />
        </div>
        <div class="col-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none"
            (click)="previousBuilding()">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.332 22C20.5411 22 22.332 20.2091 22.332 18V4C22.332 1.79086 20.5411 0 18.332 0H4.33203C2.12289 0 0.332031 1.79086 0.332031 4V18C0.332031 20.2091 2.12289 22 4.33203 22H18.332ZM4.33203 20H18.332C19.4366 20 20.332 19.1046 20.332 18V4C20.332 2.89543 19.4366 2 18.332 2H4.33203C3.22746 2 2.33203 2.89543 2.33203 4V18C2.33203 19.1046 3.22746 20 4.33203 20ZM9.86593 15.2427L11.2801 13.8285L9.45173 12H16.9888V10H9.45173L11.2801 8.17161L9.86593 6.7574L5.62329 11L9.86593 15.2427Z"
              fill="#00423E" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none"
            (click)="nextBuilding()">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.33203 22C2.12289 22 0.332031 20.2091 0.332031 18V4C0.332031 1.79086 2.12289 0 4.33203 0H18.332C20.5411 0 22.332 1.79086 22.332 4V18C22.332 20.2091 20.5411 22 18.332 22H4.33203ZM18.332 20H4.33203C3.22746 20 2.33203 19.1046 2.33203 18V4C2.33203 2.89543 3.22746 2 4.33203 2H18.332C19.4366 2 20.332 2.89543 20.332 4V18C20.332 19.1046 19.4366 20 18.332 20ZM12.7981 15.2427L11.3839 13.8285L13.2123 12H5.67521V10H13.2123L11.3839 8.17161L12.7981 6.7574L17.0407 11L12.7981 15.2427Z"
              fill="#00423E" />
          </svg>
        </div>
      </div>

      <div *ngIf="currentBuilding">
        <div *ngIf="!currentBuilding.region">
          <div class="row">
            <div class="col-4">{{'COMMON.MBC_DETAILS.BUILDING_SLRN' | translate}}</div>
            <div class="col-8">{{ currentBuilding?.slrn | hasValue }}</div>
          </div>
          <div class="alert alert-warning">
            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m2.095 19.886 9.248-16.5c.133-.237.384-.384.657-.384.272 0 .524.147.656.384l9.248 16.5c.064.115.096.241.096.367 0 .385-.309.749-.752.749h-18.496c-.44 0-.752-.36-.752-.749 0-.126.031-.252.095-.367zm9.907-6.881c-.414 0-.75.336-.75.75v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5c0-.414-.336-.75-.75-.75zm-.002-3c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                fill-rule="nonzero" />
            </svg>
            {{'POLES.EDIT.BUILDING_NOT_TAGGED' | translate}}!
          </div>
        </div>

        <div *ngIf="currentBuilding.region">
          <div class="row">
            <div class="col-4">{{'COMMON.MBC_DETAILS.BUILDING_SLRN' | translate}}</div>
            <div class="col-8">{{ currentBuilding?.slrn | hasValue }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{'COMMON.BU.SINGLE' | translate}}</div>
            <div class="col-8">{{ currentBuilding.region | hasValue }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{'COMMON.UT.SINGLE' | translate}}</div>
            <div class="col-8">{{ currentBuilding.area | hasValue }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{'COMMON.DT_NAME' | translate}}</div>
            <div class="col-8">{{ currentBuilding.dtName | hasValue }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{'COMMON.DT_NUMBER' | translate}}</div>
            <div class="col-8">{{ currentBuilding.dtNumber | hasValue }}</div>
          </div>

          <div class="row">
            <div class="col-5 header-title">
              {{'COMMON.MBC_DETAILS.CONNECTED_CUSTOMERS' | translate}}
              <span class="countOfBuildings" *ngIf="currentBuilding.connectedCustomers.length !== 0">{{
                currentCustomerPage + 1 }}</span>
              <span class="countOfBuildings" *ngIf="currentBuilding.connectedCustomers.length === 0">{{
                currentCustomerPage }}</span>
            </div>

            <div class="col-5">
              <hr />
            </div>
            <div class="col-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none"
                (click)="previousCustomer()">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.332 22C20.5411 22 22.332 20.2091 22.332 18V4C22.332 1.79086 20.5411 0 18.332 0H4.33203C2.12289 0 0.332031 1.79086 0.332031 4V18C0.332031 20.2091 2.12289 22 4.33203 22H18.332ZM4.33203 20H18.332C19.4366 20 20.332 19.1046 20.332 18V4C20.332 2.89543 19.4366 2 18.332 2H4.33203C3.22746 2 2.33203 2.89543 2.33203 4V18C2.33203 19.1046 3.22746 20 4.33203 20ZM9.86593 15.2427L11.2801 13.8285L9.45173 12H16.9888V10H9.45173L11.2801 8.17161L9.86593 6.7574L5.62329 11L9.86593 15.2427Z"
                  fill="#00423E" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none"
                (click)="nextCustomer()">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.33203 22C2.12289 22 0.332031 20.2091 0.332031 18V4C0.332031 1.79086 2.12289 0 4.33203 0H18.332C20.5411 0 22.332 1.79086 22.332 4V18C22.332 20.2091 20.5411 22 18.332 22H4.33203ZM18.332 20H4.33203C3.22746 20 2.33203 19.1046 2.33203 18V4C2.33203 2.89543 3.22746 2 4.33203 2H18.332C19.4366 2 20.332 2.89543 20.332 4V18C20.332 19.1046 19.4366 20 18.332 20ZM12.7981 15.2427L11.3839 13.8285L13.2123 12H5.67521V10H13.2123L11.3839 8.17161L12.7981 6.7574L17.0407 11L12.7981 15.2427Z"
                  fill="#00423E" />
              </svg>
            </div>
          </div>

          <div class="row">
            <div class="col-4">{{'COMMON.MBC_DETAILS.CUSTOMER_NAME' | translate}}</div>
            <div class="col-8">{{ currentCustomer.name | hasValue }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{'POLES.EDIT.CUSTOMER_ACC_NO' | translate}}</div>
            <div class="col-8">
              {{ currentCustomer.accountNumber | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{'COMMON.MBC_DETAILS.CUSTOMER_ADDRESS' | translate}}</div>
            <div class="col-8">{{ currentCustomer.address | hasValue }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{'POLES.EDIT.CUSTOMER_PHYSICAL_ADDRESS' | translate}}</div>
            <div class="col-8">
              {{ currentCustomer.physicalAddress | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{'COMMON.MBC_DETAILS.GEO_LOCATION' | translate}}</div>
            <div class="col-8" *ngIf="currentBuilding.connectedCustomers.length !== 0">
              {{ currentBuilding.longitude }} , {{ currentBuilding.latitude }}
            </div>
            <div class="col-8" *ngIf="currentBuilding.connectedCustomers.length === 0">
              {{ null | hasValue }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{'COMMON.MBC_DETAILS.METER_NO' | translate}}</div>
            <div class="col-8">
              {{ currentCustomer.meterNumber | hasValue }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="image-container main-col">
      <div class="card container">
        <div>{{'COMMON.PICTURES.LT_RIGHT_VIEW' | translate}}</div>
        <div class="images-list single-image-list">
          <ng-container
            *ngTemplateOutlet="ltPoleRightImageTemplate; context: { ltPoleRightImage: getLTPoleRightImage() }"></ng-container>
          <ng-template #ltPoleRightImageTemplate let-ltPoleRightImage="ltPoleRightImage">
            <div [ngClass]="{ 'edit-mode-enabled': editEnabled }">
              <div *ngIf="ltPoleRightImage">
                <img src="{{ ltPoleRightImage }}" (click)="showImage(ImageType.LTPoleRight)" data-bs-toggle="modal"
                  data-bs-target="#modalImage" alt="LT Pole Right image" />
              </div>
              <div *ngIf="!ltPoleRightImage || editEnabled">
                <input hidden id="ltPoleRight" type="file"
                  (change)="fileBrowserHandler($event, ImageType.LTPoleRight, editEnabled && ltPoleRightImage ? 0 : -1)"
                  [disabled]="!permissionsService.isPoleCreateEditAllowed" />
                <label class="replace-image-label" for="ltPoleRight" [ngClass]="{
                    'disabled-label': !permissionsService.isPoleCreateEditAllowed
                  }">
                  <img *ngIf="!ltPoleRightImage" src="../../../../assets/missing_image.png" alt="Missing image" />
                  <ng-container *ngIf="editEnabled && ltPoleRightImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-edit">
                      <title>Replace this photo</title>
                      <path d="M12 20h9"></path>
                      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 0l1 1a2.121 2.121 0 0 1 0 3L7 21H3v-4L16.5 3.5z"></path>
                    </svg>
                  </ng-container>

                </label>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="card container">
        <div>{{'COMMON.PICTURES.LT_LEFT_VIEW' | translate}}</div>
        <div class="images-list single-image-list">
          <ng-container
            *ngTemplateOutlet="ltPoleLeftImageTemplate; context: { ltPoleLeftImage: getLTPoleLeftImage() }"></ng-container>
          <ng-template #ltPoleLeftImageTemplate let-ltPoleLeftImage="ltPoleLeftImage">
            <div [ngClass]="{ 'edit-mode-enabled': editEnabled }">
              <div *ngIf="ltPoleLeftImage">
                <img src="{{ ltPoleLeftImage }}" (click)="showImage(ImageType.LTPoleLeft)" data-bs-toggle="modal"
                  data-bs-target="#modalImage" alt="LT Pole Left image" />
              </div>
              <div *ngIf="!ltPoleLeftImage || editEnabled">
                <input hidden id="ltPoleLeft" type="file"
                  (change)="fileBrowserHandler($event, ImageType.LTPoleLeft, editEnabled && ltPoleLeftImage ? 0 : -1)"
                  [disabled]="!permissionsService.isPoleCreateEditAllowed" />
                <label class="replace-image-label" for="ltPoleLeft" [ngClass]="{
                    'disabled-label': !permissionsService.isPoleCreateEditAllowed
                  }">
                  <img *ngIf="!ltPoleLeftImage" src="../../../../assets/missing_image.png" alt="Missing image" />
                  <ng-container *ngIf="editEnabled && ltPoleLeftImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-edit">
                      <title>Replace this photo</title>
                      <path d="M12 20h9"></path>
                      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 0l1 1a2.121 2.121 0 0 1 0 3L7 21H3v-4L16.5 3.5z"></path>
                    </svg>
                  </ng-container>

                </label>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="card container">
        <div>{{'COMMON.PICTURES.MOUNTED_TAG' | translate}}</div>
        <div class="images-list single-image-list">
          <ng-container
            *ngTemplateOutlet="mountedTagImageTemplate; context: { mountedTagImage: getBuildingMountedImage() }"></ng-container>
          <ng-template #mountedTagImageTemplate let-mountedTagImage="mountedTagImage">
            <div [ngClass]="{ 'edit-mode-enabled': editEnabled }">
              <div *ngIf="mountedTagImage">
                <img src="{{ mountedTagImage }}" (click)="showImage(ImageType.MountedTag)" data-bs-toggle="modal"
                  data-bs-target="#modalImage" alt="Building Mounted Tag image" />
              </div>
              <div *ngIf="!mountedTagImage || editEnabled">
                <input hidden id="mountedTag" type="file"
                  (change)="fileBrowserHandler($event, ImageType.MountedTag, editEnabled && mountedTagImage ? 0 : -1)"
                  [disabled]="!permissionsService.isPoleCreateEditAllowed" />
                <label class="replace-image-label" for="mountedTag" [ngClass]="{
                    'disabled-label': !permissionsService.isPoleCreateEditAllowed
                  }">
                  <img *ngIf="!mountedTagImage" src="../../../../assets/missing_image.png" alt="Missing image" />
                  <ng-container *ngIf="editEnabled && mountedTagImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-edit">
                      <title>Replace this photo</title>
                      <path d="M12 20h9"></path>
                      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 0l1 1a2.121 2.121 0 0 1 0 3L7 21H3v-4L16.5 3.5z"></path>
                    </svg>
                  </ng-container>

                </label>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="assetAssignType == AssetAssignType.Assigned" class="modal-title">{{'COMMON.ASSIGN_AGENT' | translate}}</h5>
        <h5 *ngIf="assetAssignType == AssetAssignType.Retagged" class="modal-title">{{'COMMON.RETAG_TASK' | translate}}</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          <span *ngIf="assetAssignType == AssetAssignType.Assigned">{{'COMMON.ASSIGN_TASK' | translate}}</span>
          <span *ngIf="assetAssignType == AssetAssignType.Retagged">{{'COMMON.RETAG_TASK' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
  (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">
            &lt;
          </button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modalMap" id="showMap" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closeAddModal class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map [setMapVisibility]="isMapVisible" [longitude]="longitude" [latitude]="latitude">
        </app-building-map>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="updateStatus" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <app-data-quality assetType="Pole" [assetId]="selectedPole.id"
          [areaId]="selectedPole.districtId"></app-data-quality>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="auditReview" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.FIELD_AUDIT' | translate}}</h5>
        <button type="button" #closeAudit class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <app-audit-review (closeModal)="cancel()" assetType="Pole" [assetId]="selectedPole.id"
        [areaId]="selectedPole.districtId"></app-audit-review>
    </div>
  </div>
</div>
<app-history (update)="updateHistory($event)"></app-history>

<div class="modal" id="rejectionReason" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.REASON_FOR_REJECT' | translate}}</h5>
      </div>
      <div class="p-4">
        {{ modalRejectionReason }}
      </div>
      <button type="button" #closeRejectionReason class="btn btn-primary w-50 mx-auto"  data-bs-dismiss="modal" aria-label="Close">
        {{'COMMON.CLOSE' | translate}}
      </button>
    </div>
  </div>
</div>
