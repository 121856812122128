<div class="edit-account-root">
  <div class="edit-account-header">
    <div class="user-header-info">
      <div class="user-info">
        
        <div class="name">
          {{ meterReading?.firstName | hasValue }} {{ meterReading?.surname | hasValue}}
        </div>

        <div class="application-number">
          {{ meterReading?.accountNumber }}
        </div>

        <div class="edit-accounts-body row">
          <div class="col-12">
            
            <div class="row">
              <div [ngClass]="{ 'col-4': meterReading?.status, 'col-12': !meterReading?.status }">
                <div class="entry-status" [ngClass]="meterReading?.status | getReadingStatusClass"> <!-- Change background and text color in .scss file -->
                  {{meterReading?.status | getReadingStatusClass}}</div>
              </div>
            </div>
          
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="edit-accounts-body row">
    <div class="col-4">
      <div class="row">

        <app-reading-text-box [items]="buildingInformation" [header]="'Building Information'"></app-reading-text-box>
        <app-reading-text-box *ngIf="meterReading?.priority" [items]="priorityStatus" [header]="'Priority Status'"></app-reading-text-box>
        <app-reading-text-box [items]="readingInformation" [header]="'Reading Information'"></app-reading-text-box>

      </div>
    </div>
    <div class="col-4">
      <div class="row">

        <app-reading-text-box [items]="accountInformation" [header]="'Account Information'"></app-reading-text-box>
        <app-reading-text-box [items]="meterInformation" [header]="'Meter Information'"></app-reading-text-box>
        <app-reading-text-box [items]="inaccessible" [header]="'Inaccessible'" *ngIf="meterReading?.status == ReadingStatus[ReadingStatus.Inaccessible]"></app-reading-text-box>
        <app-reading-text-box [items]="inoperableMeter" [header]="'Inoperable'" *ngIf="meterReading?.status == ReadingStatus[ReadingStatus.Inoperable]"></app-reading-text-box>

      </div>
    </div>

    <div class="image-container col-4">
      <div class="card container">
        <div [ngSwitch]="meterReading?.status">
          <div *ngSwitchCase="ReadingStatus[ReadingStatus.Assigned]">Picture of meter reading</div>
          <div *ngSwitchCase="ReadingStatus[ReadingStatus.Submitted]">Picture of meter reading</div>
          <div *ngSwitchCase="ReadingStatus[ReadingStatus.Failed]">Picture of meter reading</div>
          <div *ngSwitchCase="ReadingStatus[ReadingStatus.Rejected]">Picture of meter reading</div>
          <div *ngSwitchCase="ReadingStatus[ReadingStatus.Inaccessible]">Picture of supply cable</div>
          <div *ngSwitchCase="ReadingStatus[ReadingStatus.Inoperable]">Picture of meter</div>
        </div>
        
        <div class="images-list">
          <div>
            <img *ngIf="meterReading?.imageUrl; else noImage" src="{{ meterReading?.imageUrl }}" (click)="showImage(meterReading?.imageUrl)"
              data-bs-toggle="modal" data-bs-target="#modalImage" alt="Item image" />
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
  (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">
            &lt;
          </button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noImage>
  <img class="no-image" src="{{ placeholderImage }}" alt="Placeholder image" />
</ng-template>