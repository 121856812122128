<div class="woms">
    <div class="woms-column">
        <nav class="sidenav">
            <div class="sidenav-items">         
                <a class="nav-item" (click)="switchMenuItem(ReadingsMenuItem.MeterReadings)" 
                    [ngClass]="{'item-active': readingSelected === ReadingsMenuItem.MeterReadings}">
                    <span>Readings</span>
                </a>
                <a class="nav-item" (click)="switchMenuItem(ReadingsMenuItem.AssignedReadings)" 
                    [ngClass]="{'item-active': readingSelected === ReadingsMenuItem.AssignedReadings}">
                    <span>Assigned</span>
                </a>
            </div>
        </nav>
    </div>
    <div class="selected-wom">
        <app-meter-readings-list [readingSelected]="readingSelected" ></app-meter-readings-list>
    </div>
</div>