import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfig } from "../config/config";
import { KeyValueItem } from "../models/keyValueItem.model";
import { MeterType, NewMeter } from "../models/meter.model";
import { ResponsePackage } from "../models/util.model";
import { AssetStatusEnum } from "../models/assetStatus.model";

@Injectable({
    providedIn: 'root'
  })
export class MeterService {
    
    private pathApi = this.config.setting['pathApi'] + "meter/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});

    constructor(private http: HttpClient, private config: AppConfig) {}

    public getAllMeterTypesForSelect(utilityId: number | null = null) {
        if(utilityId) {
            return this.http.get<ResponsePackage<MeterType[]>>(`${this.pathApi}getAllMeterTypesForSelect?utilityId=${utilityId}`,{headers: this.headers, observe: 'response'});
        }
        else {
            return this.http.get<ResponsePackage<MeterType[]>>(`${this.pathApi}getAllMeterTypesForSelect`,{headers: this.headers, observe: 'response'});
        }
    }

    getAllMeterTypes(dataIn: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAllMeterTypes`, dataIn, {observe: 'response'});
    }
    
    saveMeterType(meterType: MeterType): Observable<any> {
        const formData = new FormData();
        formData.append('id', meterType.id.toString());
        formData.append('name', meterType.name);
        formData.append('utilityId', meterType.utilityId.toString());
        if (meterType.imageUrl)
            formData.append('imageUrl', meterType.imageUrl);
        
        formData.append(
        'readingStepsSerialized',
        JSON.stringify(meterType.readingSteps)
        );

        formData.append(
            '',
            JSON.stringify(meterType.readingSteps)
            );
        meterType.meterSltStatuses.map((x) => x.id).forEach((id) => {
            formData.append('sltStatusIds', id.toString());
            });
        if (meterType.imageFile)
            formData.append('imageFile', meterType.imageFile);

        return this.http.post(`${this.pathApi}saveMeterType`, formData, {observe: 'response'});
    }

    deleteMeterType(id: number): Observable<any> {
        return this.http.delete(`${this.pathApi}deleteMeterType/${id}`, {observe: 'response'});
    }

    getMeterTypesForOptions(): Observable<any> {
        return this.http.get(`${this.pathApi}getMeterTypesForOptions`, {observe: 'response'});
    }

    getAll(dataIn: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, dataIn, {observe: 'response'});
    }
    generateTemplate(utilityId: number): Observable<any> {
        return this.http.get(`${this.pathApi}generateTemplate?utilityId=${utilityId}`);
      }
    getMeterStats() {
        return this.http.get<ResponsePackage<KeyValueItem[]>>(`${this.pathApi}getStats`,{headers: this.headers, observe: 'response'});
      }
    saveMeterDetails(obj: any) {
      return this.http.post<ResponsePackage<string>>(`${this.pathApi}saveDetails`, obj, { headers: this.headers, observe: 'response' });
    }
    download(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}downloadFile`, obj, {headers: this.headers, observe: 'response'});
    }
    uploadFile(obj: any, utilityId: number): Observable<any> {
        return this.http.post(`${this.pathApi}uploadFile?utilityId=${utilityId}`, obj);
    }    
    getMeterByMeterNumber(dataIn: any,meterBrand:any): Observable<any> {
        return this.http.get(`${this.pathApi}getMeterByMeterNumber/${dataIn}/${meterBrand}`, {observe: 'response'});
    }
    save(m: NewMeter): Observable<any> {
        return this.http.post(`${this.pathApi}save`, m, {observe: 'response'});
    }
    assignMeter(buildingId: number, userId: number, assetStatus: AssetStatusEnum) {
        const obj = {entityId: buildingId, userId: userId, assetStatus: assetStatus};
        return this.http.post<ResponsePackage<string>>(`${this.pathApi}assignMeter`, obj, {observe: 'response'});
      }
}
