<div class="container-fluid customers-root">
  <div class="stats-root">
    <div
      class="stats-item row regularized-tab"
      [ngClass]="{ active: filterByValue === filterByOptions.TOTAL }"
    >
      <div
        class="col-11 regularized"
        (click)="onFilterBy(filterByOptions.TOTAL)"
      >
        <div class="regularized-title">{{ 'CUSTOMERS.TOTAL' | translate }}</div>
        <div
          *ngIf="stats.existing == undefined || stats.unvalidated == undefined"
          class="lds-ring"
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          *ngIf="stats.existing != undefined && stats.unvalidated != undefined"
          class="count-number"
        >
          {{ stats.existing + stats.unvalidated | number }}
        </div>
      </div>
      <div class="col-1">
        <div
          class="chevron"
          (click)="
            totalInfoOpen = !totalInfoOpen;
            regularizedInfoOpen = false;
            newInfoOpen = false;
            getTotalSubCardsStats()
          "
        >
          <svg
            *ngIf="!totalInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 0.757812L0.928711 2.17202L7.9998 9.24307L15.0708 2.17205L13.6566 0.757842L7.9998 6.41467L2.34292 0.757812Z"
              fill="black"
            />
          </svg>
          <svg
            *ngIf="totalInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 9.24219L0.928711 7.82798L7.9998 0.756927L15.0708 7.82799L13.6566 9.2422L7.9998 3.58537L2.34292 9.24219Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div class="total-stats-root" *ngIf="totalInfoOpen">
        <div class="stats-item regularized-extra-info">
          <div>{{ 'CUSTOMERS.ACTIVE_STATUS' | translate }}</div>
          <div
            *ngIf="stats.activeAccountStatus"
            class="count-number"
          >
            <span class="revenue">{{
              stats.activeAccountStatus | number
            }}</span>
          </div>
          <div
            *ngIf="!stats.activeAccountStatus"
            class="lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="stats-item regularized-extra-info">
          <div>{{ 'CUSTOMERS.INACTIVE_STATUS' | translate }}</div>
          <div
            *ngIf="stats.inactiveAccountStatus"
            class="count-number"
          >
            <span class="revenue">{{
              stats.inactiveAccountStatus | number
            }}</span>
          </div>
          <div
            *ngIf="!stats.inactiveAccountStatus"
            class="lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="stats-item"
      [ngClass]="{ active: filterByValue === filterByOptions.EXISTING }"
      (click)="onFilterBy(filterByOptions.EXISTING)"
    >
      <div>{{ 'COMMON.CUSTOMER.TYPE.EXISTING' | translate }}</div>
      <div *ngIf="stats.existing == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.existing != undefined" class="count-number">
        {{ stats.existing | number }}
      </div>
    </div>
    <div
      *ngIf="permissionsService.isCustomerNewEntryBreakdownAllowed"
      class="stats-item row regularized-tab"
      [ngClass]="{ active: filterByValue === filterByOptions.NEW }"
    >
      <div class="col-11 regularized" (click)="onFilterBy(filterByOptions.NEW)">
        <div class="regularized-title">{{ 'COMMON.NEW_ENTRY' | translate }}</div>
        <div *ngIf="stats.new == undefined" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div *ngIf="stats.new != undefined" class="count-number">
          {{ stats.new | number }}
        </div>
      </div>
      <div class="col-1">
        <div
          class="chevron"
          (click)="
            newInfoOpen = !newInfoOpen;
            regularizedInfoOpen = false;
            totalInfoOpen = false;
            getNewEntrySubCardsStats()
          "
        >
          <svg
            *ngIf="!newInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 0.757812L0.928711 2.17202L7.9998 9.24307L15.0708 2.17205L13.6566 0.757842L7.9998 6.41467L2.34292 0.757812Z"
              fill="black"
            />
          </svg>
          <svg
            *ngIf="newInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 9.24219L0.928711 7.82798L7.9998 0.756927L15.0708 7.82799L13.6566 9.2422L7.9998 3.58537L2.34292 9.24219Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div class="total-stats-root-new" *ngIf="newInfoOpen">
        <div
          class="stats-item regularized-extra-info"
          (click)="onFilterByNewEntry(newEntryOptions.InvalidMeterNo)"
        >
          <div>{{'CUSTOMERS.NEW_ENTRY.INVALID_METER_NO' | translate}}</div>
          <div
            *ngIf="stats.invalidMeterNumber != undefined"
            class="count-number"
          >
            <span class="revenue">{{ stats.invalidMeterNumber | number }}</span>
          </div>
          <div *ngIf="stats.invalidMeterNumber == undefined" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          class="stats-item regularized-extra-info"
          (click)="onFilterByNewEntry(newEntryOptions.AlphaMeterType)"
        >
          <div>{{'CUSTOMERS.NEW_ENTRY.ALPHA_METER' | translate}}</div>
          <div *ngIf="stats.alphaMeterType != undefined" class="count-number">
            <span class="revenue">{{ stats.alphaMeterType | number }}</span>
          </div>
          <div *ngIf="stats.alphaMeterType == undefined" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          class="stats-item regularized-extra-info"
          (click)="onFilterByNewEntry(newEntryOptions.MeterStartsWith)"
        >
          <div>{{'CUSTOMERS.NEW_ENTRY.METER_NO_24' | translate}}</div>
          <div
            *ngIf="stats.meterNumberStartsWith != undefined"
            class="count-number"
          >
            <span class="revenue">{{
              stats.meterNumberStartsWith | number
            }}</span>
          </div>
          <div
            *ngIf="stats.meterNumberStartsWith == undefined"
            class="lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          class="stats-item regularized-extra-info"
          (click)="onFilterByNewEntry(newEntryOptions.WithAccNo)"
        >
          <div>{{'CUSTOMERS.NEW_ENTRY.WITH_ACC_NUMBER' | translate}}</div>
          <div *ngIf="stats.withAccNo != undefined" class="count-number">
            <span class="revenue">{{ stats.withAccNo | number }}</span>
          </div>
          <div *ngIf="stats.withAccNo == undefined" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          class="stats-item regularized-extra-info"
          (click)="onFilterByNewEntry(newEntryOptions.WithSPN)"
        >
          <div>{{'CUSTOMERS.NEW_ENTRY.WITH_SPN' | translate}}</div>
          <div *ngIf="stats.withSpn != undefined" class="count-number">
            <span class="revenue">{{ stats.withSpn | number }}</span>
          </div>
          <div *ngIf="stats.withSpn == undefined" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="stats-root stats-root-second-row">
    <div
      *ngIf="permissionsService.isCustomerRegularizationAllowed
      "
      class="stats-item row regularized-tab"
      [ngClass]="{
        active: filterByValue === filterByOptions.REGULARIZED
      }"
    >
      <div
        class="col-11 regularized"
        (click)="onFilterBy(filterByOptions.REGULARIZED)"
      >
        <div class="regularized-title">{{'CUSTOMERS.REGULARIZED.TITLE' | translate}}</div>
        <div *ngIf="stats.regularized == undefined" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div *ngIf="stats.regularized != undefined" class="count-number">
          {{ stats.regularized | number }}
        </div>
      </div>
      <div class="col-1">
        <div
          class="chevron"
          (click)="
            regularizedInfoOpen = !regularizedInfoOpen;
            totalInfoOpen = false;
            newInfoOpen = false;
            getRegularizedSubCardsStats()
          "
        >
          <svg
            *ngIf="!regularizedInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 0.757812L0.928711 2.17202L7.9998 9.24307L15.0708 2.17205L13.6566 0.757842L7.9998 6.41467L2.34292 0.757812Z"
              fill="black"
            />
          </svg>
          <svg
            *ngIf="regularizedInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 9.24219L0.928711 7.82798L7.9998 0.756927L15.0708 7.82799L13.6566 9.2422L7.9998 3.58537L2.34292 9.24219Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div class="regularized-stats-root" *ngIf="regularizedInfoOpen">
        <div class="stats-item regularized-extra-info">
          <div>{{'CUSTOMERS.REGULARIZED.TOTAL_REVENUE' | translate}}</div>
          <div *ngIf="stats.regularizedTotalRevenue" class="count-number">
            <span class="currency">{{ currency }}</span
            ><span class="revenue">{{
              stats.regularizedTotalRevenue | number
            }}</span>
          </div>
          <div
            *ngIf="!stats.regularizedTotalRevenue"
            class="lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          class="stats-item regularized-extra-info"
        >
          <div>{{'CUSTOMERS.REGULARIZED.REGULARIZED' | translate}}</div>
          <div *ngIf="stats.regularizedConsidered && stats.regularized"
            class="count-number">
            {{
              (stats.regularizedConsidered / stats.regularized) * 100
                | number : "1.0-0"
            }}%
            <span class="paying-customers">
              {{ stats.regularizedConsidered | number }} of
              {{ stats.regularized | number }}
            </span>
          </div>
          <div
          *ngIf="!(stats.regularizedConsidered && stats.regularized)"
            class="lds-ring"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="stats-item"
      [ngClass]="{ active: filterByValue === filterByOptions.ONBOARD }"
      (click)="onFilterBy(filterByOptions.ONBOARD)"
    >
      <div>{{'CUSTOMERS.DIRECT' | translate}}</div>
      <div *ngIf="stats.onboard == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.onboard != undefined" class="count-number">
        {{ stats.onboard | number }}
      </div>
    </div>
    <div
      class="stats-item"
      [ngClass]="{ active: filterByValue === filterByOptions.RETAGGED }"
      (click)="onFilterBy(filterByOptions.RETAGGED)"
    >
      <div>{{'CUSTOMERS.RETAGGED' | translate}}</div>
      <div *ngIf="stats.retagged == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.retagged != undefined" class="count-number">
        {{ stats.retagged | number }}
      </div>
    </div>
    <div
      *ngIf="permissionsService.isCustomerUnvisitedAllowed"
      class="stats-item"
    >
      <div>{{'CUSTOMERS.UNVISITED' | translate}}</div>
      <div *ngIf="stats.unvalidated == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.unvalidated != undefined" class="count-number">
        {{ stats.unvalidated | number }}
      </div>
    </div>
  </div>

  <div class="table-root">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="d-flex">
        <div id="customer_search" class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div id="customer_search-by" class="search-type-picker">
          <div [title]="selectedSearchTypeDisplay | translate"
            class="dropdown"
            (click)="showSelectSearchType = !showSelectSearchType"
            click-stop-propagation
          >
            <span>{{'COMMON.BY' | translate}}:</span>
            <span>{{ selectedSearchTypeDisplay | translate }}</span>
            <span class="arrow"
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div
              [id]="'customer_search-by-' + (i + 1)"
              (click)="selectSearchType(searchType)"
              *ngFor="let searchType of searchByOptions; let i = index"
            >
              <div>{{ searchType.translation | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div id="customer_date-type" class="datetype-picker">
              <div [title]="selectedDateTypeDisplay | translate"
                class="dropdown"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span>{{'COMMON.DATE_TYPE.TITLE' | translate}}:</span>
                <span>{{ selectedDateTypeDisplay | translate }}</span>
                <span class="arrow"
                  ><img
                    src="../../assets/icons/gray-arrow-down.svg"
                    alt="Gray arrow down"
                /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  [id]="'customer_date-type-' + (i + 1)"
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes; let i = index"
                >
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div id="customer_date-from" class="datepicker-container" style="width: 23rem;">
              <label for="dateFrom"> {{'COMMON.FROM' | translate}}: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div id="customer_date-to" class="datepicker-container">
              <label for="dateTo" class="right-label"> {{'COMMON.TO' | translate}}: </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>
        <div
          id="customer_sort-by"
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown" [title]="(sortByLabelValue.translation | translate) + (sortByLabelValue.arrow !== 'no' ? (sortByLabelValue.arrow === 'up' ? ' A.' : ' D.') : '')">
            <span>{{'COMMON.SORT_BY.TITLE' | translate}}:</span>
            <span
              >{{ sortByLabelValue.translation | translate
              }}<img
                *ngIf="sortByLabelValue.arrow !== 'no'"
                [ngClass]="{ 'arrow-up': sortByLabelValue.arrow === 'up' }"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow"
            /></span>
            <span
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div
            class="dropdown-opened v-2"
            *ngIf="showSortBy && filterByValue != filterByOptions.ONBOARDED"
          >
            <div id="customer_sort-by-newest" (click)="sortBy(sortByOptions.NEWEST)">
              <div>{{'COMMON.SORT_BY.NEWEST' | translate}}</div>
            </div>
            <div id="customer_sort-by-slrn-asc" (click)="sortBy(sortByOptions.SLRN_ASC)">
              <div>{{'COMMON.SLRN' | translate}}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div id="customer_sort-by-slrn-desc" (click)="sortBy(sortByOptions.SLRN_DESC)">
              <div>{{'COMMON.SLRN' | translate}}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
            <div id="customer_sort-by-acc-no-asc" (click)="sortBy(sortByOptions.ACCNO_ASC)">
              <div>{{'COMMON.SORT_BY.ACCOUNT_NO' | translate}}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div id="customer_sort-by-asc-no-desc" (click)="sortBy(sortByOptions.ACCNO_DESC)">
              <div>{{'COMMON.SORT_BY.ACCOUNT_NO' | translate}}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
          </div>
          <div
            class="dropdown-opened"
            *ngIf="showSortBy && filterByValue == filterByOptions.ONBOARDED"
          >
            <div (click)="sortBy(sortByOptions.NEWEST)">
              <div>{{'CUSTOMERS.SORT_BY.NEWEST' | translate}}</div>
            </div>
            <div (click)="sortBy(sortByOptions.OLDEST)">
              <div>{{'CUSTOMERS.SORT_BY.OLDEST' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="d-block poles">
          <div
            class="d-flex align-items-center data clickable nav-item"
            style="border: 2px solid#00423E"
            (click)="showDownload = !showDownload"
          >
            <span>{{'COMMON.DOWNLOAD' | translate}}</span>
            <div class="ms-auto">
              <img
                class="arrow-icon"
                src="../../../assets/icons/dropdown.svg"
                alt="Arrow Up"
              />
            </div>
          </div>
          <div *ngIf="showDownload" class="download-options">
            <div
              class="asset-grouped"
              [ngClass]="{
                'selected-download': downloadCustomer
              }"
              (click)="downloadCustomer = true; downloadMissingSlrn = false"
            >
              <span class="asset-name">{{'CUSTOMERS.DOWNLOAD.CUSTOMERS' | translate}}</span>
            </div>
            <div
              class="asset-grouped"
              [ngClass]="{
                'selected-download': downloadMissingSlrn
              }"
              (click)="downloadMissingSlrn = true; downloadCustomer = false"
            >
              <span class="asset-name">{{'CUSTOMERS.DOWNLOAD.SLRN' | translate}}</span>
            </div>
            <button class="btn btn-primary btn-apply" (click)="download()">
              <span>{{'COMMON.APPLY' | translate}}</span>
            </button>
          </div>
        </div>
        <div
          *ngIf="
            filterByValue === filterByOptions.NEW &&
            isRegularizationUploadVisible &&
            permissionsService.isCustomerRegularizationAllowed
          "
        >
          <button
            [routerLink]="'/upload/11/' + utilityId"
            class="btn btn-primary"
            click-stop-propagation
          >
            <div>
              <img
                style="margin-right: 0rem !important"
                src="../../assets/icons/upload-arrow.svg"
              />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        {{'COMMON.FILTER' | translate}}
        <app-multi-select-dropdown
          class="multiselect"
          [id]="'customer_region_filter'"
          [clicked]="clicked"
          [items]="regions"
          [label]="'COMMON.BU.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
          (applyFilterEmitter)="applyRegionFilter()"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          [id]="'customer_area_filter'"
          class="multiselect"
          [clicked]="clicked"
          [items]="areas"
          [label]="'COMMON.UT.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
          (applyFilterEmitter)="applyAreaFilter()"
        >
        </app-multi-select-dropdown>
        <select
          id="customer_tariff_filter"
          *ngIf="filterByValue != filterByOptions.ONBOARDED"
          name="tariff-select"
          (change)="selectTariff($event)"
          class="form-select"
        >
          <option value="0" [selected]="tariff == 0" disabled>{{'COMMON.TARIFF.SINGLE' | translate}}</option>
          <option
            [id]="'customer_tariff_filter-' + (i + 1)"
            [selected]="tariff == o.id"
            *ngFor="let o of tariffs; let i = index"
            value="{{ o.id }}"
          >
            {{ o.name }}
          </option>
        </select>

        <select
          id="customer_user-type_filter"
          *ngIf="
            isServiceTypeFieldVisible &&
            filterByValue != filterByOptions.ONBOARDED
          "
          name="st-select"
          (change)="selectUserType($event)"
          class="form-select"
        >
          <option value="" [selected]="!userType" disabled>{{'COMMON.USER.TYPE.TITLE' | translate}}</option>
          <option
            [id]="'customer_usertype_filter-' + (i + 1)"
            [selected]="userType == o.value"
            *ngFor="let o of userTypeOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate}}
          </option>
        </select>

        <app-multi-select-dropdown
          [id]="'customer_user_filter'"
          class="multiselect"
          [searchFilterActive]="true"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'COMMON.USER.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
          (applyFilterEmitter)="applyUserFilter()"
        >
        </app-multi-select-dropdown>
      
        <select
          id="customer_validation-status_filter"
          *ngIf="permissionsService.isValidationStatusAllowed"
          name="status-select"
          (change)="selectStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!status" disabled>
            {{'COMMON.VALIDATION_STATUS' | translate}}
          </option>
          <option
            [id]="'customer_validation-status_filter-' + (i + 1)"
            [selected]="status == s.value"
            *ngFor="let s of statusOptions; let i = index"
            value="{{ s.value }}"
          >
            {{ s.translation | translate}}
          </option>
        </select>
        <select
          id="customer_audit_status_filter"
          *ngIf="permissionsService.isCustomerAuditReviewAllowed"
          name="audit-status-select"
          (change)="selectAuditStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!auditStatus" disabled>
            {{'COMMON.AUDIT_STATUS' | translate}}
          </option>
          <option
            [selected]="auditStatus == s.value"
            *ngFor="let s of auditStatusOptions"
            value="{{ s.value }}"
          >
            {{ s.translation | translate}}
          </option>
        </select>
        <select
          id="customer_tag_filter"
          name="tag-select"
          (change)="selectTag($event)"
          class="form-select"
        >
          <option value="" [selected]="!tag" disabled>{{'COMMON.TAG.TITLE' | translate}}</option>
          <option
            [id]="'customer_tag_filter-' + (i + 1)"
            [selected]="tag == t.value"
            *ngFor="let t of tagOptions; let i = index"
            value="{{ t.value }}"
          >
            {{ t.translation | translate}}
          </option>
        </select>
        <select
          id="customer_slt-status_filter"
          name="slt-select"
          (change)="selectSltStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!sltStatus" disabled>
            {{ 'COMMON.SLT_STATUS.SINGLE' | translate }}
          </option>
          <option
            [id]="'customer_slt-status_filter-' + (i + 1)"
            [selected]="sltStatus == s.key"
            *ngFor="let s of sltOptions; let i = index"
            value="{{ s.key }}"
          >
            {{ s.value }}
          </option>
        </select>

        <select
          id="customer_uop_filter"
          name="uop-select"
          (change)="selectUseOfPremises($event)"
          class="form-select"
        >
          <option value="" [selected]="!useOfPremises" disabled>
            {{'COMMON.USE_OF_PREMISES' | translate}}
          </option>
          <option
            [id]="'customer_uop_filter-' + (i + 1)"
            [selected]="useOfPremises == u.value"
            *ngFor="let u of uopOptions; let i = index"
            value="{{ u.value }}"
          >
            {{ u.translation | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          [useApply]="true"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
          (applyFilterEmitter)="applyFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="customers">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th id="customer_table_header_checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th id="customer_table_header_slrn">{{'COMMON.SLRN' | translate}}</th>
              <th id="customer_table_header_building-owner">{{'COMMON.TABLE_HEADERS.BUILDING_OWNER' | translate}}</th>
              <th id="customer_table_header_acct-no">{{'COMMON.TABLE_HEADERS.ACCT_NO' | translate}}</th>
              <th id="customer_table_header_meter-no">{{'COMMON.TABLE_HEADERS.METER_NO' | translate}}</th>
              <th id="customer_table_header_dt-name">{{'COMMON.DT_NAME' | translate}}</th>
              <th id="customer_table_header_address">{{'COMMON.ADDRESS' | translate}}</th>
              <th id="customer_table_header_region">{{'COMMON.BU.SINGLE' | translate}}</th>
              <th id="customer_table_header_district">{{'COMMON.UT.SINGLE' | translate}}</th>
              <th id="customer_table_header_tariff">{{'COMMON.TARIFF.SINGLE' | translate}}</th>
              <th id="customer_table_header_validation-status" *ngIf="permissionsService.isValidationStatusAllowed">{{'COMMON.VALIDATION_STATUS' | translate}}</th>
              <th id="customer_table_header_audit-status" *ngIf="permissionsService.isCustomerAuditReviewAllowed">{{'COMMON.AUDIT_STATUS' | translate}}</th>
              <th id="customer_table_header_tag">{{'COMMON.TAG.TITLE' | translate}}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr [id]="'customer_table_row-' + (i + 1)" *ngFor="let customer of customers; let i = index">
              <td [id]="'customer_table_checkbox-' + (i + 1)">
                <input
                  type="checkbox"
                  (click)="checkCustomer(customer.id)"
                  class="form-check-input"
                  [ngModel]="customer.isChecked"
                />
              </td>
              <td
                [id]="'customer_table_slrn-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.slrn | hasValue }}
              </td>
              <td
              [id]="'customer_table_building-owner-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.buildingOwner | hasValue }}
              </td>
              <td
                [id]="'customer_table_acct-no-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.accountNumber | hasValue }}
              </td>
              <td
                [id]="'customer_table_meter-number-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.meterNo | hasValue }}
              </td>
              <td
                [id]="'customer_table_dt-name-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.dtName | hasValue }}
              </td>
              <td
                [id]="'customer_table_address-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.physicalAddress | hasValue }}
              </td>
              <td
                [id]="'customer_table_region-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.area.regionName | hasValue }}
              </td>
              <td
                [id]="'customer_table_district-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.area.name | hasValue }}
              </td>
              <td
                [id]="'customer_table_tariff-' + (i + 1)"
                (click)="setFilterStorage()"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                {{ customer.tariffName | hasValue }}
              </td>
              <td [id]="'customer_table_validation-status-' + (i + 1)" *ngIf="permissionsService.isValidationStatusAllowed">
                <div *ngIf="customer.assetStatus === AssetStatus.Assigned" class="orange-status v-2">
                  {{ 'COMMON.ASSET_STATUS.ASSIGNED' | translate }}
                </div>
                <div *ngIf="customer.dqStatus?.toString() === 'Approved' && customer.assetStatus !== AssetStatus.Assigned" class="green-status v-2">
                  {{ 'COMMON.ASSET_STATUS.COMPLETE' | translate }}
                </div>
                <div *ngIf="customer.dqStatus?.toString() === 'Rejected' && customer.assetStatus !== AssetStatus.Assigned" class="dark-red-status v-2">
                  {{ 'COMMON.ASSET_STATUS.REJECTED' | translate }}
                </div>
                <div *ngIf="!customer.dqStatus && customer.assetStatus !== AssetStatus.Assigned" class="gray-status v-2">
                  {{ 'COMMON.ASSET_STATUS.PENDING' | translate }}
                </div>
              </td>
              
              <td [id]="'customer_table_audit_status-' + (i + 1)" *ngIf="permissionsService.isCustomerAuditReviewAllowed">
                <div>
                  {{(customer.isAudited ? 'COMMON.ASSET_STATUS.AUDITED' : 'COMMON.ASSET_STATUS.NON_AUDITED') | translate}}
                </div>
              </td>
              <td [id]="'customer_table_tag-' + (i + 1)">
                <div *ngIf="!customer.isOnBoard">
                  <div *ngIf="!customer.isNew" class="dark-green-status v-2">
                    {{'COMMON.EXISTING' | translate}}
                  </div>
                  <div *ngIf="customer.isNew" class="green-status v-2">
                    {{'COMMON.NEW_ENTRY' | translate}}
                  </div>
                </div>
                <div *ngIf="customer.isOnBoard">
                  <div class="olive-green-status v-2">{{'CUSTOMERS.DIRECT' | translate}}</div>
                </div>
              </td>
              <td class="td-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-bs-toggle="modal"
                  data-bs-target="#history"
                  (click)="viewHistory(customer.id)"
                  width="22"
                  height="22"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
                  />
                </svg>
              </td>
              <td
                (click)="setFilterStorage()"
                class="more-icon"
                [routerLink]="[
                  customer.isOnBoard ? 'edit-onboard' : 'edit',
                  customer.id
                ]"
              >
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <app-paging
        *ngIf="count > 0"
        [pageSize]="pageSize"
        [useDirect]="true"
        [count]="count"
        [currentPage]="currentPage"
        (pageChange)="pageChange($event)"
      ></app-paging>
    </div>
  </div>
</div>
<button
  class="btn btn-primary"
  #openUpdateDescriptionModal
  data-bs-toggle="modal"
  data-bs-target="#history"
  hidden
></button>

<app-history
  [isViewHistory]="isViewHistory"
  [historyItems]="historyItems"
></app-history>
