import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Area } from 'src/app/models/area.model';
import {
  DtEdit,
  DTHeader,
  getInitDtEdit,
  initDTHeader,
  KvaOption,
  mapDtEditToViewSave,
} from 'src/app/models/dt.model';
import { F11Crud } from 'src/app/models/feeder11.model';
import { F33Crud } from 'src/app/models/feeder33.model';
import { HistoryOutput } from 'src/app/models/history.model';
import { ImageType } from 'src/app/models/image.model';
import {
  CommunicationStatus,
  MeterCondition,
  MeterType,
  getInitMeter,
} from 'src/app/models/meter.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { SubstationCrud } from 'src/app/models/substation.model';
import { TransmissionStation } from 'src/app/models/transmission.station.model';
import { User } from 'src/app/models/user.model';
import { DtService } from 'src/app/services/dt.service';
import { MeterService } from 'src/app/services/meter.service';
import { UserService } from 'src/app/services/user.service';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { AuthService } from 'src/app/services/auth.service';
import { AssetStatusEnum } from 'src/app/models/assetStatus.model';
import { TranslationUtils } from 'src/assets/i18n/translation.utils';
import { BlobStorageService } from 'src/app/_modules/shared/_services/blob-storage.service';
import { ContainerNames } from 'src/app/models/containerNames.model';

@Component({
  selector: 'app-edit-transformer',
  templateUrl: './edit-transformer.component.html',
  styleUrls: ['./edit-transformer.component.scss'],
})
export class EditTransformerComponent implements OnInit {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @ViewChild('userFilterInput') userFilter: ElementRef;
  @ViewChild('closeAudit') auditReviewModal: ElementRef;

  AssetAssignType = AssetStatusEnum;
  assetAssignType: AssetStatusEnum;

  public PermissionsEnum = PermissionsEnum;
  isDataQuality: boolean = false;

  dtId: number = 0;
  id: number = 0;
  ImageType = ImageType;
  selectedImage: string = '';
  kvaOptions: KvaOption[] = [
    { value: '50KVA', viewValue: '50KVA' },
    { value: '100KVA', viewValue: '100KVA' },
    { value: '200KVA', viewValue: '200KVA' },
    { value: '300KVA', viewValue: '300KVA' },
    { value: '500KVA', viewValue: '500KVA' },
    { value: '750KVA', viewValue: '750KVA' },
    { value: '1000KVA', viewValue: '1000KVA' },
    { value: '2000KVA', viewValue: '2000KVA' },
    { value: 'OTHER', viewValue: 'Other' },
  ];

  mobileUsers: User[] = [];
  transmissionStations: TransmissionStation[] = [];
  substations: SubstationCrud[] = [];
  feeders11: F11Crud[] = [];
  feeders33: F33Crud[] = [];
  areas: Area[] = [];
  @Input() dateManufacturer: Date;
  @Input() dateComission: Date;
  dateManufacturerString: string;
  dateComissionString: string;
  assignedUserId: number = 0;
  newImage: File | null;
  modalRejectionReason?: string = '';
  doesMeterNeedRepositioning?: boolean;

  dtHeaderInfo: DTHeader = initDTHeader();
  metered: boolean = false;
  dt: DtEdit = getInitDtEdit();
  editEnabled: boolean = false;
  communicationOptions = [
    {
      name: 'TRANSFORMERS.EDIT.COMMUNICATING_WITH_AMI',
      value: 'CommunicatingWithAmiSystem',
    },
    {
      name: 'TRANSFORMERS.EDIT.NOT_COMMUNICATING_WITH_AMI',
      value: 'NotCommunicatingWithAmiSystem',
    },
  ];
  meterConditionOptions = [
    { name: 'COMMON.METER_CONDITION.WORKING', value: 'Working' },
    { name: 'COMMON.METER_CONDITION.NOT_WORKING', value: 'NotWorking' },
    { name: 'COMMON.METER_CONDITION.BURNT', value: 'Burnt' },
  ];
  physicalMeterTypeOptions = [
    {
      name: 'TRANSFORMERS.EDIT.PHYSICAL_METER_TYPE.WITH_COM',
      value: 'SmartWithCoMModule',
    },
    {
      name: 'TRANSFORMERS.EDIT.PHYSICAL_METER_TYPE.WITHOUT_COM',
      value: 'SmartWithoutCoMModule',
    },
    { name: 'TRANSFORMERS.EDIT.PHYSICAL_METER_TYPE.ANALOG', value: 'Analog' },
    { name: 'COMMON.OTHER', value: 'Other' },
  ];
  proposedLocationOptions = [
    {
      name: 'TRANSFORMERS.EDIT.PROPOSED_LOCATION_OPTIONS.CLOSE',
      value: 'Close to DT point',
    },
    {
      name: 'TRANSFORMERS.EDIT.PROPOSED_LOCATION_OPTIONS.BESIDE',
      value: 'Beside feeder pillar',
    },
  ];
  xlpeCableConditionOptions = [
    { name: 'TRANSFORMERS.EDIT.XLPE_CABLE_CONDITIONS.GOOD', value: 'Good' },
    { name: 'TRANSFORMERS.EDIT.XLPE_CABLE_CONDITIONS.BAD', value: 'Bad' },
    { name: 'TRANSFORMERS.EDIT.XLPE_CABLE_CONDITIONS.BURNT', value: 'Burnt' },
  ];
  xlpeCableSizeOptions = [
    { value: '25mm²', viewValue: '25mm²' },
    { value: '35mm²', viewValue: '35mm²' },
    { value: '50mm²', viewValue: '50mm²' },
    { value: '70mm²', viewValue: '70mm²' },
    { value: '95mm²', viewValue: '95mm²' },
    { value: '120mm²', viewValue: '120mm²' },
    { value: '150mm²', viewValue: '150mm²' },
    { value: '240mm²', viewValue: '240mm²' },
    { value: '500mm²', viewValue: '500mm²' },
  ];
  meterTypeOptions: MeterType[] = [];
  history: HistoryOutput = new HistoryOutput();

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('openUpdateDescriptionModal')
  openUpdateDescriptionModal: ElementRef;
  @ViewChild('closeUpdateHistoryModal') closeUpdateHistoryModal: ElementRef;

  filterText: string = '';
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  dtsConnectedTo11KVFeeder: string = '';
  dtsConnectedTo33KVFeeder: string = '';
  isGhanaPostFieldsVisible: boolean = true;
  isMapVisible: boolean = false;
  showRecallMenu: boolean = false;
  recallAssignedAllowed: boolean = false;
  recallValidatedAllowed: boolean = false;
  timeZone: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private dtService: DtService,
    private meterService: MeterService,
    private translationService: TranslationService,
    public permissionsService: PermissionsService,
    private el: ElementRef,
    private renderer: Renderer2,
    private blobStorageService: BlobStorageService
  ) {}

  ngOnInit(): void {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.blobStorageService.setContainer(ContainerNames.Images);
    this.setFieldsVisibility();
    const routeId = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(routeId == null ? '0' : routeId);
    this.getDtDetails(this.id);
    this.getMeterTypes();
    this.imgElement = this.el?.nativeElement?.querySelector(
      '.ngxImageZoomThumbnail'
    );
    this.imgZoomFullContainer =
      this.el?.nativeElement?.querySelector('.ngxImageZoomFull');
    var path = this.route.snapshot.url[0].path;
    if (path == 'data-quality') {
      this.isDataQuality = true;
    }
  }

  setModalType(type: AssetStatusEnum) {
    this.assetAssignType = type;
  }

  selectAssignUser(e: any) {
    this.assignedUserId = +e.target.value;
  }

  selectFencingStatus(e: any) {
    this.dt.fencingStatus = e.target.value;
  }
  selectMountPosition(e: any) {
    this.dt.mountPosition = e.target.value;
  }
  selectDtStatus(e: any) {
    this.dt.dtStatus = e.target.value;
  }
  selectKvaRating(e: any) {
    this.dt.kvaRating = e.target.value;
  }

  selectType(e: any) {
    this.dt.type = e.target.value;
  }
  setDateOfManufacturer() {
    this.dt.dateOfManufacturer = new Date(this.dateManufacturerString);
  }
  setDateOfComission() {
    this.dt.dateOfCommissioning = new Date(this.dateComissionString);
  }
  formatFencingStatus(fencingStatus: any) {
    switch (fencingStatus) {
      case 'NoFence':
        return 'No fence';
      case 'Fenced':
        return 'Fenced';
      case 'Compact':
        return 'Compact';
      default:
        return '';
    }
  }

  formatCommunicationStatus(communicationStatus: any) {
    switch (communicationStatus) {
      case 'CommunicatingWithAmiSystem':
        return 'Communicating With Ami System';
      case 'NotCommunicatingWithAmiSystem':
        return 'Not Communicating With AMI System';
      default:
        return '';
    }
  }
  formatFeederCondition(feederCondition: any) {
    switch (feederCondition) {
      case 'BadCleanUp':
        return 'Bad CleanUp';
      case 'BadReplacement':
        return 'Bad Replacement';
      default:
        return feederCondition;
    }
  }

  assignTask(): void {
    if (
      this.mobileUsers.filter((x) => x.fullName == this.filterText.trim())
        .length > 0
    ) {
      this.assignedUserId = this.mobileUsers.filter(
        (x) => x.fullName == this.filterText.trim()
      )[0].id;
      this.tempAssignedUserId = this.assignedUserId;
    } else {
      this.tempAssignedUserId = 0;
    }
    if (this.tempAssignedUserId != 0) {
      this.dtService
        .assignDt(
          this.id,
          this.assignedUserId,
          this.assetAssignType,
          this.isDataQuality
        )
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.closeModal?.nativeElement?.click();
              this.dt.assignedUserId = this.assignedUserId;
              this.toastr.success('Successfully assigned user');
              window.location.reload();
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    } else {
      this.toastr.warning('Please select valid user.');
    }
  }
  renderLocationText(longitude?: number, latitude?: number) {
    if (longitude == null || latitude == null) {
      return 'No location given';
    }
    return latitude + ' , ' + longitude;
  }
  disableUpdateStatusButton() {
    if (this.dt.dataQualityId === 0) return false;
    else return true;
  }
  openDropdown() {
    this.isDropdownOpened = true;
  }
  formatHeaderInfo(dt: DtEdit) {
    this.dtHeaderInfo.name = dt.name;
    this.dtHeaderInfo.slrn = dt.slrn ? dt.slrn : '';
    (this.dtHeaderInfo.dtStatus = dt.dtStatus || 0),
      (this.dtHeaderInfo.locationAddress = dt.locationAddress
        ? dt.locationAddress
        : '');
  }

  private getDtDetails(dtId: any) {
    this.dtService.getDetails(dtId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.dt = responseData.data;
          console.log(this.dt);
          console.log(this.timeZone);
          this.metered = this.dt.meter ? true : false;
          this.dt.meter = this.dt.meter ? this.dt.meter : getInitMeter();
          this.dateComissionString = this.dt.dateOfCommissioning
            ?.toLocaleString()
            .split('T')[0];
          this.dateManufacturerString = this.dt.dateOfManufacturer
            ?.toLocaleString()
            .split('T')[0];
          this.assignedUserId = this.dt.assignedUserId || 0;
          this.tempAssignedUserId = this.assignedUserId;
          this.filterText = this.dt.assignedUser;
          this.doesMeterNeedRepositioning =
            this.dt.proposedLocation != undefined;
          this.formatHeaderInfo(this.dt);

          if (this.dt.feeder11) {
            this.dtsConnectedTo11KVFeeder = `11KV${
              this.dt.feeder11.substation
                ? '-' + this.dt.feeder11.substation
                : ''
            } ${this.dt.feeder11.name ? 'INJ-' + this.dt.feeder11.name : ''}-${
              this.dt.name
            }`;
          }

          if (this.dt.feeder33) {
            this.dtsConnectedTo33KVFeeder = `33KV${
              this.dt.feeder33.transmissionStation
                ? '-' + this.dt.feeder33.transmissionStation
                : ''
            } ${this.dt.feeder33.name ? 'TS-' + this.dt.feeder33.name : ''}-${
              this.dt.name
            }`;
          }

          if (
            this.permissionsService.isTransformersRecallAssignedAllowed &&
            this.dt.status === 'Assigned'
          ) {
            this.recallAssignedAllowed = true;
          }
          if (
            this.dt.dataValidationReview != null &&
            this.dt.dataValidationReview.length > 0
          ) {
            this.modalRejectionReason = this.dt.dataValidationReview.find(
              (x) => x.key == 'Reason for rejection'
            )?.value;
          }
          if (
            this.permissionsService.isTransformersRecallValidatedAllowed &&
            this.dt.dataQualityId !== 0
          ) {
            this.recallValidatedAllowed = true;
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  onModalAssignFade() {
    this.filterText = this.dt.assignedUser;
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.dt.area?.id, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            } else {
              this.isDropdownOpened = true;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }
  getMeterTypes() {
    this.meterService.getAllMeterTypesForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.meterTypeOptions = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getMountedTagImage() {
    return this.dt.images.find((x) => x.imageType.toString() === 'MountedTag')
      ?.url;
  }

  getLocationImage() {
    return this.dt.images.find((x) => x.imageType.toString() === 'Location')
      ?.url;
  }

  getInputCableImage() {
    return this.dt.images.find((x) => x.imageType.toString() === 'InputCable')
      ?.url;
  }

  getOutputCableImage() {
    return this.dt.images.find((x) => x.imageType.toString() === 'OutputCable')
      ?.url;
  }

  getNameplateImage() {
    return this.dt.images.find(
      (x) => x.imageType.toString() === 'NameplatePicture'
    )?.url;
  }
  getDtMeterNumberImage() {
    return this.dt.images.find((x) => x.imageType.toString() === 'MeterNumber')
      ?.url;
  }
  disableAuditReviewButton() {
    if (this.dt.auditedStatus === 'Audited') return true;
    else return false;
  }

  cancelAudit() {
    this.auditReviewModal?.nativeElement?.click();
  }

  showImage(imageType: ImageType) {
    switch (imageType) {
      case ImageType.MountedTag: {
        this.selectedImage = this.getMountedTagImage() || '';
        break;
      }
      case ImageType.Location: {
        this.selectedImage = this.getLocationImage() || '';
        break;
      }
      case ImageType.InputCable: {
        this.selectedImage = this.getInputCableImage() || '';
        break;
      }
      case ImageType.OutputCable: {
        this.selectedImage = this.getOutputCableImage() || '';
        break;
      }
      case ImageType.NameplatePicture: {
        this.selectedImage = this.getNameplateImage() || '';
        break;
      }
      case ImageType.MeterNumber: {
        this.selectedImage = this.getDtMeterNumberImage() || '';
        break;
      }
      case ImageType.Location: {
        this.selectedImage = this.getLocationImage() || '';
        break;
      }
      default:
        this.selectedImage = '';
    }
  }

  fileBrowserHandler($event: any, type: ImageType, imageId: number) {
    this.newImage = $event.target.files.item(0);

    if (!this.newImage) {
      this.toastr.error('Image data is missing');
      return;
    }

    // Upload the image to blob storage first
    this.blobStorageService.uploadFile(
      this.newImage,
      this.newImage.name,
      (uploadedUrl) => {
        const obj = {
          imageType: type,
          entityId: this.id,
          guid: this.dt.guid,
          url: uploadedUrl,
          imageId: imageId,
        };

        this.dtService.uploadPicture(obj, this.newImage!).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              const images = this.dt.images.filter(
                (x) => x.imageType.toString() == ImageType[type]
              );
              const imageIndex = this.dt.images.indexOf(images[imageId]);
              this.dt.images[imageIndex] = responseData.data;
              this.toastr.success('Successfully added image');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occurred while saving image metadata');
          },
        });
      },
      (error) => {
        console.error('Error uploading image to blob storage:', error);
        this.toastr.error('Failed to upload image to storage');
      }
    );
  }

  edit() {
    this.editEnabled = true;
  }

  updateHistory(history: any) {
    this.history = history;
    this.dt.history = this.history;

    this.save();
  }

  validateInput(event: Event) {
    if (!this.dt.locationLandmark || !this.dt.locationAddress) {
      this.toastr.error(
        'Closest landmark and building owner can not be empty!'
      );
      return;
    }
    if (!this.dt.impedancePercentage) {
      this.toastr.error('Impedance Percentage can not be empty!');
      return;
    }
    if (this.metered) {
      if (
        this.dt.meter?.number == '' ||
        this.dt.meter?.physicalMeterType == undefined ||
        this.dt.meter.meterType.id == 0 ||
        this.dt.meter.communicationStatus == CommunicationStatus.NotSelected ||
        this.dt.meter.condition == MeterCondition.NotSelected
      ) {
        this.toastr.error('Please enter all meter information!');
        return;
      }
    }
    this.openUpdateDescriptionModal?.nativeElement?.click();
  }

  save() {
    this.dt.id = this.id;

    this.dt.assignedUserId = this.assignedUserId;
    if (!this.metered) {
      this.dt.meter = undefined;
    }
    this.dtService.save(mapDtEditToViewSave(this.dt)).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success(responseData?.message);
          this.editEnabled = false;
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
        this.openUpdateDescriptionModal?.nativeElement?.click();
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }
  }

  cancel() {
    this.editEnabled = false;
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.dt.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }
  recall() {
    this.dtService.recall(this.id).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully recalled transformer.');
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  rotateRight() {
    this.currentRotation += 90;
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside =
      this.isDropdownOpened &&
      (this.dropdownContainer?.nativeElement?.contains(event.target) ||
        this.userFilter?.nativeElement?.contains(event.target));

    if (!clickedInside) {
      this.isDropdownOpened = false;
    }
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find(
      (el: any) => el === 'ghanaPostCode'
    )
      ? false
      : true;
  }

  viewConnectedAssets(type: any, dtId: any, name: any) {
    const navigationExtras: NavigationExtras = {
      state: { dtId: dtId, dtName: name },
    };
    switch (type) {
      case 1:
        this.router.navigate(['customers'], navigationExtras);
        break;
      case 2:
        this.router.navigate(['buildings'], navigationExtras);
        break;
      case 3:
        this.router.navigate(['poles'], navigationExtras);
        break;
      default:
        this.router.navigate(['customers'], navigationExtras);
        break;
    }
  }

  onRepositioningChange(value?: boolean) {
    if (value == false) this.dt.proposedLocation = undefined;
  }
}
