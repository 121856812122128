import { InstallationAndPowerSource } from "./customer.model";
import { Meter, TypeOfMeter } from "./meter.model";

export class InstallationTableData {
  id : number = 0;
  accountNumber: string = '';
  accountName: string = '';
  address: string = '';
  currentTariff: string = '';
  region: string = '';
  district: string = '';
  feederName: string = '';
  dtName: string = '';
  oldMeterNo: string = '';
  assignedDate: string = '';
  approvedDate :string='';
  rejectedDate:string='';
  assignedUser: string = '';
  assignedBy: string = '';
  approvedBy: string='';
  rejectedBy : string = '';
  currentStatus: string = '';
}
export enum SortByOptions {
  NEWEST = 1,
  OLDEST = 2
}
export class InstallationMaterialRequirements {
  id: number = 0;
  installationMaterial: string = '';
  materialCategory: string = '';
  quantity: number = 0;
  unitOfMeasurement: string = '';

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.installationMaterial = data?.installationMaterial ?? '';
    this.materialCategory = data?.materialCategory ?? '';
    this.quantity = data?.quantity ?? 0;
    this.unitOfMeasurement = data?.unitOfMeasurement ?? '';
  }
}
export class InstallationAssign{
  assignedUserId :number=0;
  customers:number[]=[];
}

export class InstallationDetails{
  existingCableSize: string = '';
  existingMeterEnclosure: string = '';
  inputCableDistance: string = '';
  installationType: string = '';
  meterLocation: string = '';
  numberOfServiceWires: string = '';
  outputCableDistance: string = '';
  recommendedCableSize: string = '';
  recommendedMeterType: string = '';
  serviceWireTraceableToMeterPoint: string = '';
  serviceWiresCondition: string = '';
  installationStatus: string = '';
  remarksAndRecommendations: string = '';
  existingMeterInLocation:string='';
  reviewedBy:string = "";
  username : string = "";
  dt: InstallationDt = new InstallationDt();
  customer: InstallationCustomer = new InstallationCustomer();
  installationMaterialRequirements: InstallationMaterialRequirements[] = [];
  images : InstallationImage[] = [];
  latitude: number;
  longitude: number;
  constructor(data?: any) {
    this.existingCableSize = data?.existingCableSize ?? '';
    this.existingMeterEnclosure = data?.existingMeterEnclosure ?? '';
    this.inputCableDistance = data?.inputCableDistance ?? '';
    this.installationType = data?.installationType ?? '';
    this.meterLocation = data?.meterLocation ?? '';
    this.numberOfServiceWires = data?.numberOfServiceWires ?? '';
    this.outputCableDistance = data?.outputCableDistance ?? '';
    this.recommendedCableSize = data?.recommendedCableSize ?? '';
    this.recommendedMeterType = data?.recommendedMeterType ?? '';
    this.serviceWireTraceableToMeterPoint = data?.serviceWireTraceableToMeterPoint ?? '';
    this.serviceWiresCondition = data?.serviceWiresCondition ?? '';
    this.installationStatus = data?.installationStatus ?? '';
    this.remarksAndRecommendations = data?.remarksAndRecommendations ?? '';
    this.existingMeterInLocation = data?.existingMeterInLocation ?? '';
    this.reviewedBy = data?.reviewedBy ?? '';
    this.username = data?.username ?? '';
    this.dt = new InstallationDt(data?.dt);
    this.customer = new InstallationCustomer(data?.customer);
    this.installationMaterialRequirements = data?.installationMaterialRequirements?.length ? data?.installationMaterialRequirements.map((el: any) => new InstallationMaterialRequirements(el)) : [];
    this.images = data?.images?.length ? data?.images.map((el: any) => new InstallationImage(el)) : [];
    this.latitude = data?.latitude ?? 0;
    this.longitude = data?.longitude ?? 0;

  }
}
export class InstallationImage {
  url: string;
  imageType: any;

  constructor(data?: any) {
    this.url = data?.url ?? '';
    this.imageType = data?.imageType ?? '';
  }
}
export class InstallationDt {
  dtId: string = '';
  dtName: string = '';
  dtNumber: string = '';
  feederId: string = '';
  feederName: string = '';
  feederNumber: string = '';
  isFeeder11 : string = '';

  constructor(data?: any) {
    this.dtId = data?.dtId ?? '';
    this.dtName = data?.dtName ?? '';
    this.dtNumber = data?.dtNumber ?? '';
    this.feederId = data?.feederId ?? '';
    this.feederName = data?.feederName ?? '';
    this.feederNumber = data?.feederNumber ?? '';
    this.isFeeder11 = data?.isFeeder11 ?? '';
  }
}

export class InstallationCustomer {
  accountName: string = '';
  accountNumber: string = '';
  address: string = '';
  areaName: string = '';
  buildingOwner: string = '';
  customerId: 3;
  customerType: string = '';
  email: string = '';
  latitude: number = 0;
  longitude: number = 0;
  meterStatus: string = '';
  name: string = '';
  oldMeter: Meter;
  regionName: string = '';
  spn: string = '';
  tarifId: number = 0;
  tarifName: string = '';
  tarifRate: string = '';
  typeOfConnection: string = '';
  geoCode: string = '';
  readingOnMeter: string = '';
  ghanaPostGPS: string = '';
  activityCode: string = '';
  currentPlotCode: string = '';
  meterManufacturer:string='';
  meterSealNumber:string = '';
  meterSerialNumber :string= "";
  meterType:string='';
  meterNumber: string = '';
  sltStatus: string = '';
  installationAndPowerSource: InstallationAndPowerSource;
  recommendedTariff: string = '';
  typeOfMeter?: string='';
  rating?: string = '';
  ctRating?: string = '';
  vtRating?: string = '';
  boxSeal?: string = '';
  ttbSeal?: string = '';
  ctSeal?: string = '';
  meterTypeDetails:string='';
  areaId: number = 0;

  constructor(data?: any) {
    console.log(data)
    this.accountName = data?.accountName ?? '';
    this.accountNumber = data?.accountNumber ?? '';
    this.address = data?.address ?? '';
    this.areaName = data?.areaName ?? '';
    this.buildingOwner = data?.buildingOwner ?? '';
    this.customerId = data?.customerId ?? 3;
    this.customerType = data?.customerType ?? '';
    this.email = data?.email ?? '';
    this.latitude = data?.latitude ?? 0;
    this.longitude = data?.longitude ?? 0;
    this.meterStatus = data?.meterStatus ?? '';
    this.name = data?.name ?? '';
    this.oldMeter = data?.oldMeter ?? '';
    this.regionName = data?.regionName ?? '';
    this.spn = data?.spn ?? '';
    this.tarifId = data?.tarifId ?? 0;
    this.tarifName = data?.tarifName ?? '';
    this.tarifRate = data?.tarifRate ?? '';
    this.typeOfConnection = data?.typeOfConnection ?? '';
    this.geoCode = data?.geoCode ?? '';
    this.readingOnMeter = data?.readingOnMeter ?? '';
    this.ghanaPostGPS = data?.ghanaPostGPS ?? '';
    this.activityCode = data?.activityCode ?? '';
    this.currentPlotCode = data?.currentPlotCode ?? '';
    this.meterManufacturer = data?.meterManufacturer ?? '';
    this.meterSealNumber = data?.meterSealNumber ?? '';
    this.meterSerialNumber = data?.meterSerialNumber ?? '';
    this.meterType = data?.meterType ?? '';
    this.meterNumber = data?.meterNumber ?? '';
    this.sltStatus = data?.sltStatus ?? '';
    this.installationAndPowerSource =  data?.installationAndPowerSource ?? '';
    this.recommendedTariff = data?.recommendedTariff ?? '';
    this.typeOfMeter = data?.typeOfMeter ?? '';
    this.rating = data?.rating ?? '';
    this.ctRating = data?.ctRating ?? '';
    this.vtRating = data?.vtRating ?? '';
    this.boxSeal = data?.boxSeal ?? '';
    this.ttbSeal = data?.ttbSeal ?? '';
    this.ctSeal = data?.ctSeal ?? '';
    this.meterTypeDetails = data?.meterTypeDetails?.replace("_"," ") ?? '';
    this.areaId = data?.areaId ?? 0;
  }
}